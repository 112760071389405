// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FC } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  BLUE_1,
  BLUE_2,
  BLUE_3,
  BLUE_4,
  BLUE_5,
  GREY_1,
  GREY_2,
  GREY_3,
} from '../../../../styles/constants';
import { formatNumber } from '../../../../utils/formatters';
import { getColumnOptions } from '../../../../utils/defaultChartConfig';
import { useFlatLayout, useStoreState } from '../../../../hooks';

export interface LayoutData {
  date: string;
  value: number;
  layout: string | null;
  availability: string;
  count?: number;
}
interface Props {
  data: Data[];
}

export const SaleSpeedLayoutChart: FC<Props> = ({ data }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { getLayout } = useFlatLayout();
  const translateValue = (value: string): string => getLayout(value);
  const { displayValues } = useStoreState((state) => state.filters);

  const config: ColumnConfig = getColumnOptions({
    data: data.sort(
      (a, b) =>
        new Date(a.date).getTime() - new Date(b.date).getTime() ||
        b.value - a.value,
    ),
    color: [BLUE_5, BLUE_4, BLUE_3, BLUE_2, GREY_1, GREY_2, GREY_3, BLUE_1],
    xField: 'date',
    yField: 'value',
    seriesField: 'layout',
    isStack: true,
    tooltip: {
      formatter: (datum) => {
        const value = datum as Data;
        return {
          name: String(value.layout),
          value: value.value ?? '-',
        };
      },
      title: (title) =>
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          year: 'numeric',
        }),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item?.color
              }"></div>
              <div class="bmt-label">${translateValue(item?.name)}:</div>
              <div class="bmt-value">${formatNumber(item?.value)}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
    xAxis: {
      label: {
        formatter: (value) => {
          const dateValue = new Date(value);
          return dateValue.toLocaleDateString(language, {
            month: 'long',
          });
        },
      },
    },
    legend: {
      itemName: {
        formatter: (value) => translateValue(value),
      },
    },
  });

  const chartConfig = {
    ...config,
    columnStyle: {
      radius: [0, 0, 0, 0],
    },
    label: displayValues
      ? {
          position: 'middle',
          style: {
            fill: '#000000',
            opacity: 0.6,
          },
          layout: [{ type: 'adjust-color' }],
        }
      : null,
  };

  return data.length > 0 ? (
    <Column {...chartConfig} />
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
