/** @jsxImportSource @emotion/react */
import { FC, useEffect, useState } from 'react';
import { Affix, Button, Col, Row, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { SegmentedValue } from 'antd/lib/segmented';
import { ChevronDoubleLeftIcon } from '@heroicons/react/24/outline';
import { PriceListTable, Page } from '../../components';
import { useBreakpoints, useStoreActions, useStoreState } from '../../hooks';
import { PriceListFilters } from '../../components/priceList/PriceListFilters';
import { styles } from './styles';
import { PricePerSmCalcType } from '../../components/TopBar/SettingsPopover';
import { PriceListGrid } from '../../components/priceList/grid/PriceListGrid';
import { SubscribePage } from '../../components/SubscribePage/SubscribePage';
import { transformPricingListData } from '../../utils/utils';
import { DetailPreviewCardUpdated } from '../../components/priceListDetail/DetailPreviewCard/DetailPreviewCardUpdated';
import { PriceListFloorPlan } from '../../components/priceList/floorPlan/PriceListFloorPlan';

enum FilterEnum {
  GRAPHIC,
  LIST,
  HEATMAP,
  GRID,
  GOOGLE_ANALYTICS,
  FLOOR_PLAN,
}

export const PriceListPage: FC = () => {
  const { isSmallScreen } = useBreakpoints();
  const priceListFilterValue = localStorage.getItem('priceListFilter');
  const [showCard, setShowCard] = useState<boolean>(true);
  const [filterValue, setFilterValue] = useState<SegmentedValue>(
    FilterEnum.LIST,
  );
  const isDashboardEnabled = useStoreState(
    (state) => state.user.profile?.dashboard_enabled,
  );
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const selectedPricingData = useStoreState(
    (state) => state.priceList.selectedPricingData,
  );
  const { showAnalyticsUpdated } = useStoreState((state) => state.priceList);
  const { projectId, isLoading } = useStoreState(
    (state) => state.filters,
  );
  const { overviewData } = useStoreState((state) => state.filters);
  const hasFloorPlan = overviewData?.projects.find((p) => p.project_id === projectId)?.has_floor_floorplans;
  const pricePerSmCalculation = useStoreState(
    (state) =>
      state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const VAT_included = useStoreState(
    (state) => state.user.profile?.VAT_included,
  );
  const fetchData = useStoreActions((actions) => actions.priceList.fetchData);
  const { t } = useTranslation();

  const handleChange = (value: SegmentedValue): void => {
    setFilterValue(value);
    localStorage.setItem('priceListFilter', value.toString());
  };

  useEffect(() => {
    if (filterValue === FilterEnum.FLOOR_PLAN) {
      setShowCard(true);
    }
  }, [filterValue]);

  useEffect(() => {
    if (priceListFilterValue) {
      handleChange(Number(priceListFilterValue));
    }
  }, [priceListFilterValue]);

  useEffect(() => {
    if (filterValue === FilterEnum.GOOGLE_ANALYTICS) {
      setFilterValue(FilterEnum.LIST);
    }
  }, [showAnalyticsUpdated]);

  useEffect(() => {
    if (!isLoading && projectId) {
      fetchData({
        projectId,
      });
    }
  }, [
    projectId,
    isLoading,
    fetchData,
    pricePerSmCalculation,
    VAT_included,
  ]);

  return (
    <Page
      pageClassname='price-list'
      filters={
        isDashboardEnabled &&
        !isSmallScreen && (
          <PriceListFilters
            showLayoutFilter={filterValue !== FilterEnum.LIST}
          />
        )
      }
      title={
        <div css={styles.titleContainer}>
          <div css={styles.title}>{t('price_list.title')}</div>
          {isDashboardEnabled && !isSmallScreen && (
            <div className='flex items-center'>
              <Segmented
                value={filterValue}
                options={[
                  {
                    value: FilterEnum.LIST,
                    label: t('price_list.switch.list'),
                  },
                  ...(hasFloorPlan ? [
                    {
                      value: FilterEnum.FLOOR_PLAN,
                      label: t('price_list.switch.floor_plan', 'Floor plan'),
                    },
                    ] : []
                  ),
                  {
                    value: FilterEnum.GRAPHIC,
                    label: t('price_list.switch.graphic'),
                  },

                  {
                    value: FilterEnum.GRID,
                    label: t('price_list.switch.grid'),
                  },
                  {
                    value: FilterEnum.HEATMAP,
                    label: t('price_list.switch.heatmap'),
                  },
                  ...(showAnalyticsUpdated
                    ? [
                        {
                          value: FilterEnum.GOOGLE_ANALYTICS,
                          label: t('price_list.switch.google_analytics'),
                        },
                      ]
                    : []),
                ]}
                onChange={handleChange}
              />
            </div>
          )}
        </div>
      }
    >
      {isDashboardEnabled ? (
        <Row css={styles.contentContainer} gutter={[16, 0]}>
          <Col xs={24} lg={showCard ? 18 : 23}>
            {filterValue === FilterEnum.LIST && <PriceListTable />}
            {filterValue === FilterEnum.GRAPHIC && (
              <PriceListGrid viewType='graphic' />
            )}
            {filterValue === FilterEnum.GRID && (
              <PriceListGrid viewType='grid' />
            )}
            {filterValue === FilterEnum.HEATMAP && (
              <PriceListGrid viewType='heatmap' />
            )}
            {filterValue === FilterEnum.GOOGLE_ANALYTICS && (
              <PriceListGrid viewType='analytics' />
            )}
            {filterValue === FilterEnum.FLOOR_PLAN && (
              <PriceListFloorPlan />
            )}
          </Col>
          {showCard && !isSmallScreen ? (
            <Col xs={24} lg={6} css={styles.rightColumn} ref={setContainer}>
              <Affix css={styles.affix} offsetTop={1} target={() => container}>
                <div css={styles.affix}>
                  {selectedPricingData && (
                    <DetailPreviewCardUpdated
                      showDetailButton
                      onHide={filterValue === FilterEnum.FLOOR_PLAN ? undefined : () => setShowCard(false)}
                      data={transformPricingListData(selectedPricingData)}
                    />
                  )}
                </div>
              </Affix>
            </Col>
          ) : (
            !isSmallScreen && filterValue !== FilterEnum.FLOOR_PLAN && (
              <Col md={1}>
                <Button onClick={() => setShowCard(true)} css={styles.iconBtn}>
                  <ChevronDoubleLeftIcon className='w-8 stroke-2' />
                </Button>
              </Col>
            )
          )}
        </Row>
      ) : (
        <SubscribePage featureType='dashboard' />
      )}
    </Page>
  );
};
