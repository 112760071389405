/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { Segmented } from 'antd';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import { Card } from '../../Card';
import { PriceDetailFilter } from '../../../api/enums';
import {
  useRegionFormatting,
  useStoreActions,
  useStoreState,
} from '../../../hooks';
import { styles } from './styles';
import { ProjectType } from '../../../store/types';
import { PriceBreakdownTypes } from '../../../api/mockData/pricingPriceListDetaildata';
import { InteriorUpdated } from './InteriorUpdated';
import { ExteriorUpdated } from './ExteriorUpdated';
import { FloorUpdated } from './FloorUpdated';
import { OrientationUpdated } from './OrientationUpdated';
import { LayoutUpdated } from './LayoutUpdated';
import { SalesPlanUpdated } from './SalesPlanUpdated';
import { PriceSumsType } from '../../../api/mockData/priceListDetailData';
import { Rounding } from './Rounding';
import { formatPercent } from '../../../utils';
import { roundUpdate } from '../../../utils/utils';
import { InfoPopover } from '../../InfoPopover';

interface PriceBreakdownProps {
  data: PriceBreakdownTypes;
  price: number;
  priceData?: PriceSumsType;
  isHouse?: boolean;
  isUpdateFrozen: boolean;
}

export const PriceBreakdownUpdated: FC<PriceBreakdownProps> = (props) => {
  const { formatCurrency, areaUnit, applyRounding } = useRegionFormatting();
  const setPriceDetailFilter = useStoreActions(
    (actions) => actions.priceListDetail.setPriceDetailFilter,
  );
  const { showPredictionUpdated } = useStoreState(
    (state) => state.priceListDetail,
  );
  const priceDetailFilter = useStoreState(
    (state) => state.priceListDetail.priceFilter,
  );
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;

  const {
    data: propsData,
    priceData,
    isHouse: isHouseType,
    price,
    isUpdateFrozen,
  } = props;

  const {
    // analytics,
    exterior,
    floor,
    interior,
    layout,
    orientation,
    sales_plan,
  } = propsData;

  const isHouse = isHouseType ?? !!propsData?.floor.floor_count;

  const handleChange = (value: PriceDetailFilter): void => {
    setPriceDetailFilter(value);
  };

  const [data, setData] = useState({
    // analytics,
    exterior,
    floor,
    interior,
    layout,
    orientation,
    sales_plan,
  });

  const priceSums = useMemo(() => {
    if (!priceData) return null;
    const {
      recommendedPrice,
      priceUpdate,
      recommendedPricePsm,
      priceUpdatePsm,
      priceUpdatePercentage,
    } = priceData;

    switch (priceDetailFilter) {
      case PriceDetailFilter.PRICE_PER_SM:
        return {
          recommendedPrice: recommendedPricePsm,
          priceUpdate: priceUpdatePsm,
        };
      case PriceDetailFilter.PERCENTAGE:
        return {
          recommendedPrice,
          priceUpdate: priceUpdatePercentage,
        };
      default:
        return {
          recommendedPrice,
          priceUpdate,
        };
    }
  }, [priceData, priceDetailFilter]);

  const roundedUpdate = useMemo(() => {
    if (!priceSums) return 0;

    return roundUpdate(
      priceSums.recommendedPrice,
      priceSums.priceUpdate,
      price,
      applyRounding,
    );
  }, [priceSums]);

  const rounding = useMemo(() => {
    if (!priceSums) return 0;

    return (
      applyRounding(priceSums.recommendedPrice) - priceSums.recommendedPrice
    );
  }, [priceSums]);

  const isPercentage = priceDetailFilter === PriceDetailFilter.PERCENTAGE;
  const isPerSM = priceDetailFilter === PriceDetailFilter.PRICE_PER_SM;

  useEffect(() => {
    switch (priceDetailFilter) {
      case PriceDetailFilter.PRICE_PER_SM:
        setData({
          // analytics: {
          //   ...analytics,
          // },
          interior: {
            floor_area: {
              ...interior.floor_area,
              price: interior.floor_area.price_per_sm,
              update_price: interior.floor_area.update_price_per_sm,
            },
            difference_floor_area: {
              ...interior.difference_floor_area,
              price: interior.difference_floor_area?.price_per_sm,
              update_price: interior.difference_floor_area?.update_price_per_sm,
            },
            layout: {
              ...interior.layout,
              price: interior.layout.price_per_sm,
              update_price: interior.layout.update_price_per_sm,
            },
            layout_type: {
              ...interior.layout_type,
              price: interior.layout_type.price_per_sm,
              update_price: interior.layout_type.update_price_per_sm,
            },
          },
          exterior: {
            ...exterior,
            ...(!isHouse
              ? {
                  balcony_area: {
                    ...exterior.balcony_area,
                    price: exterior.balcony_area.price_per_sm,
                    update_price: exterior.balcony_area.update_price_per_sm,
                  },
                  garden_area: {
                    ...exterior.garden_area,
                    price: exterior.garden_area.price_per_sm,
                    update_price: exterior.garden_area.update_price_per_sm,
                  },
                  terrace_area: {
                    ...exterior.terrace_area,
                    price: exterior.terrace_area.price_per_sm,
                    update_price: exterior.terrace_area.update_price_per_sm,
                  },
                }
              : {
                  lot_area: {
                    ...exterior.lot_area,
                    price: exterior.lot_area.price_per_sm,
                    update_price: exterior.lot_area.update_price_per_sm,
                  },
                  garage_count: {
                    ...exterior.garage_count,
                    price: exterior.garage_count.price_per_sm,
                    update_price: exterior.garage_count.update_price_per_sm,
                  },
                }),
          },
          floor: isHouse
            ? {
                ...floor,
                floor_count: {
                  ...floor.floor_count,
                  price: floor.floor_count.price_per_sm,
                  update_price: floor.floor_count.update_price_per_sm,
                },
              }
            : {
                ...floor,
                floor: {
                  ...floor.floor,
                  price: floor.floor.price_per_sm,
                  update_price: floor.floor.update_price_per_sm,
                },
                top_floor: {
                  ...floor.top_floor,
                  price: floor.top_floor.price_per_sm,
                  update_price: floor.top_floor.update_price_per_sm,
                },
                bottom_floor: {
                  ...floor.bottom_floor,
                  price: floor.bottom_floor.price_per_sm,
                  update_price: floor.bottom_floor.update_price_per_sm,
                },
              },
          orientation: {
            ...orientation,
            cardinal_direction: {
              ...orientation.cardinal_direction,
              price: orientation.cardinal_direction.price_per_sm,
              update_price: orientation.cardinal_direction.update_price_per_sm,
            },
            distance: {
              ...orientation.distance,
              price: orientation.distance.price_per_sm,
              update_price: orientation.distance.update_price_per_sm,
            },
            view: {
              ...orientation.view,
              price: orientation.view.price_per_sm,
              update_price: orientation.view.update_price_per_sm,
            },
            ...(isHouse
              ? {}
              : {
                  sides: {
                    ...orientation.sides,
                    price: orientation.sides.price_per_sm,
                    update_price: orientation.sides.update_price_per_sm,
                  },
                }),
          },
          layout: {
            ...layout,
            ...(isHouse
              ? {}
              : {
                  category: {
                    ...layout.category,
                    price: layout.category.price_per_sm,
                    update_price: layout.category.update_price_per_sm,
                  },
                }),
            ...(isHouse
              ? {
                  house_type: {
                    ...layout.house_type,
                    price: layout.house_type.price_per_sm,
                    update_price: layout.house_type.update_price_per_sm,
                  },
                }
              : {}),
            sunny_kitchen: {
              ...layout.sunny_kitchen,
              price: layout.sunny_kitchen.price_per_sm,
              update_price: layout.sunny_kitchen.update_price_per_sm,
            },
            ...(isHouse
              ? {}
              : {
                  next_to_elevator: {
                    ...layout.next_to_elevator,
                    price: layout.next_to_elevator.price_per_sm,
                    update_price: layout.next_to_elevator.update_price_per_sm,
                  },
                }),
            noise: {
              ...layout.noise,
              price: layout.noise.price_per_sm,
              update_price: layout.noise.update_price_per_sm,
            },
            bathrooms: {
              ...layout.bathrooms,
              price: layout.bathrooms.price_per_sm,
              update_price: layout.bathrooms.update_price_per_sm,
            },
            ...(isHouse
              ? {}
              : {
                  permit_regular: {
                    ...layout.permit_regular,
                    price: layout.permit_regular.price_per_sm,
                    update_price: layout.permit_regular.update_price_per_sm,
                  },
                }),
            bedrooms_ratio: {
              ...layout.bedrooms_ratio,
              price: layout.bedrooms_ratio.price_per_sm,
              update_price: layout.bedrooms_ratio.update_price_per_sm,
            },
            living_area_ratio: {
              ...layout.living_area_ratio,
              price: layout.living_area_ratio.price_per_sm,
              update_price: layout.living_area_ratio.update_price_per_sm,
            },
            walk_through_room: {
              ...layout.walk_through_room,
              price: layout.walk_through_room.price_per_sm,
              update_price: layout.walk_through_room.update_price_per_sm,
            },
          },
          sales_plan: {
            price_effect: {
              ...sales_plan.price_effect,
              price: sales_plan.price_effect.price_per_sm,
              update_price: sales_plan.price_effect.update_price_per_sm,
            },
          },
        });
        break;
      case PriceDetailFilter.PERCENTAGE:
        setData({
          // analytics: {
          //   ...analytics,
          // },
          interior: {
            floor_area: {
              ...interior.floor_area,
              price: interior.floor_area.price_percentage,
              update_price: interior.floor_area.update_price_percentage,
            },
            difference_floor_area: {
              ...interior.difference_floor_area,
              price: interior.difference_floor_area?.price_percentage,
              update_price:
                interior.difference_floor_area?.update_price_percentage,
            },
            layout: {
              ...interior.layout,
              price: interior.layout.price_per_sm,
              update_price: interior.layout.update_price_percentage,
            },
            layout_type: {
              ...interior.layout_type,
              price: interior.layout_type.price_per_sm,
              update_price: interior.layout_type.update_price_percentage,
            },
          },
          exterior: {
            ...exterior,
            has_exterior: exterior.has_exterior && {
              ...exterior.has_exterior,
              price: exterior.has_exterior.price_percentage,
              update_price: exterior.has_exterior.update_price_percentage,
            },
            ...(!isHouse
              ? {
                  balcony_area: {
                    ...exterior.balcony_area,
                    price: exterior.balcony_area.price_percentage,
                    update_price: exterior.balcony_area.update_price_percentage,
                  },
                  garden_area: {
                    ...exterior.garden_area,
                    price: exterior.garden_area.price_percentage,
                    update_price: exterior.garden_area.update_price_percentage,
                  },
                  terrace_area: {
                    ...exterior.terrace_area,
                    price: exterior.terrace_area.price_percentage,
                    update_price: exterior.terrace_area.update_price_percentage,
                  },
                }
              : {
                  lot_area: {
                    ...exterior.lot_area,
                    price: exterior.lot_area.price_percentage,
                    update_price: exterior.lot_area.update_price_percentage,
                  },
                  garage_count: {
                    ...exterior.garage_count,
                    price: exterior.garage_count.price_percentage,
                    update_price: exterior.garage_count.update_price_percentage,
                  },
                }),
          },
          floor: isHouse
            ? {
                ...floor,
                floor_count: {
                  ...floor.floor_count,
                  price: floor.floor_count.price_percentage,
                  update_price: floor.floor_count.update_price_percentage,
                },
              }
            : {
                ...floor,
                floor: {
                  ...floor.floor,
                  price: floor.floor.price_percentage,
                  update_price: floor.floor.update_price_percentage,
                },
                top_floor: {
                  ...floor.top_floor,
                  price: floor.top_floor.price_percentage,
                  update_price: floor.top_floor.update_price_percentage,
                },
                bottom_floor: {
                  ...floor.bottom_floor,
                  price: floor.bottom_floor.price_percentage,
                  update_price: floor.bottom_floor.update_price_percentage,
                },
              },
          orientation: {
            ...orientation,
            cardinal_direction: {
              ...orientation.cardinal_direction,
              price: orientation.cardinal_direction.price_percentage,
              update_price:
                orientation.cardinal_direction.update_price_percentage,
            },
            distance: {
              ...orientation.distance,
              price: orientation.distance.price_percentage,
              update_price: orientation.distance.update_price_percentage,
            },
            view: {
              ...orientation.view,
              price: orientation.view.price_percentage,
              update_price: orientation.view.update_price_percentage,
            },
            ...(isHouse
              ? {}
              : {
                  sides: {
                    ...orientation.sides,
                    price: orientation.sides.price_percentage,
                    update_price: orientation.sides.update_price_percentage,
                  },
                }),
          },
          layout: {
            ...layout,
            ...(isHouse
              ? {
                  house_type: {
                    ...layout.house_type,
                    price: layout.house_type.price_percentage,
                    update_price: layout.house_type.update_price_percentage,
                  },
                }
              : {
                  category: {
                    ...layout.category,
                    price: layout.category.price_percentage,
                    update_price: layout.category.update_price_percentage,
                  },
                  next_to_elevator: {
                    ...layout.next_to_elevator,
                    price: layout.next_to_elevator.price_percentage,
                    update_price:
                      layout.next_to_elevator.update_price_percentage,
                  },
                  permit_regular: {
                    ...layout.permit_regular,
                    price: layout.permit_regular.price_percentage,
                    update_price: layout.permit_regular.update_price_percentage,
                  },
                }),
            sunny_kitchen: {
              ...layout.sunny_kitchen,
              price: layout.sunny_kitchen.price_percentage,
              update_price: layout.sunny_kitchen.update_price_percentage,
            },
            noise: {
              ...layout.noise,
              price: layout.noise.price_percentage,
              update_price: layout.noise.update_price_percentage,
            },
            bathrooms: {
              ...layout.bathrooms,
              price: layout.bathrooms.price_percentage,
              update_price: layout.bathrooms.update_price_percentage,
            },
            bedrooms_ratio: {
              ...layout.bedrooms_ratio,
              price: layout.bedrooms_ratio.price_percentage,
              update_price: layout.bedrooms_ratio.update_price_percentage,
            },
            living_area_ratio: {
              ...layout.living_area_ratio,
              price: layout.living_area_ratio.price_percentage,
              update_price: layout.living_area_ratio.update_price_percentage,
            },
            walk_through_room: {
              ...layout.walk_through_room,
              price: layout.walk_through_room.price_percentage,
              update_price: layout.walk_through_room.update_price_percentage,
            },
          },
          sales_plan: {
            price_effect: {
              ...sales_plan.price_effect,
              price: sales_plan.price_effect.price_percentage,
              update_price: sales_plan.price_effect.update_price_percentage,
            },
          },
        });
        break;
      default:
        setData({
          // analytics,
          exterior,
          floor,
          interior,
          layout,
          orientation,
          sales_plan,
        });
    }
  }, [
    // analytics,
    exterior,
    floor,
    interior,
    layout,
    orientation,
    priceDetailFilter,
    sales_plan,
  ]);

  return (
    <Card
      className='price-breakdown'
      titleExtra={
        data.interior.floor_area.price_per_sm ? (
          <Segmented
            style={{ marginLeft: 'auto' }}
            value={priceDetailFilter ?? PriceDetailFilter.PRICE}
            options={[
              {
                value: PriceDetailFilter.PRICE,
                label: isRent ? t('common.rent') : t('common.price'),
              },
              {
                value: PriceDetailFilter.PRICE_PER_SM,
                label: isRent
                  ? t('rentals.rent_per_sm')
                  : t('daily_news.kpi.price_per_sm'),
              },
              {
                value: PriceDetailFilter.PERCENTAGE,
                label: '%',
              },
            ]}
            onChange={(val) => handleChange(val as PriceDetailFilter)}
          />
        ) : null
      }
      title={t('price_list.detail.price_breakdown.title')}
      // subtitle={t('price_list.detail.price_breakdown.subtitle')}
    >
      <div className='grid auto-rows-auto gap-10 pb-10'>
        <div className='grid grid-cols-4 gap-10 text-center items-center font-bold'>
          <div className='col-span-2' />
          <div className='flex justify-between'>
            <span>{t('price_list.detail.price_breakdown.value', 'Value')}</span>
            <span>
              {t('price_list.detail.price_breakdown.price_part', 'Price Part')}
            </span>
          </div>
          <div>{t('price_list.detail.price_breakdown.update', 'Update')}</div>
        </div>
        <InteriorUpdated data={data.interior} isHouse={isHouse} />
        <ExteriorUpdated data={data.exterior} isHouse={isHouse} />
        <FloorUpdated data={data.floor} isHouse={isHouse} />
        <OrientationUpdated data={data.orientation} isHouse={isHouse} />
        <LayoutUpdated data={data.layout} isHouse={isHouse} />
        <SalesPlanUpdated data={data.sales_plan} />
        <Rounding
          recommendedPrice={priceSums?.recommendedPrice ?? 0}
          isPercentage={isPercentage}
          priceUpdate={priceSums?.priceUpdate ?? 0}
          roundedUpdate={roundedUpdate}
        />
        {/* <Analytics data={data.analytics} /> */}
      </div>
      {showPredictionUpdated && priceSums && (
        <section css={styles.footer}>
          <div css={styles.recommendedPrice}>
            <div css={styles.description}>
              {isRent
                ? t('price_list.detail.price_breakdown.recommended_rent', {
                    unit: areaUnit,
                  })
                : t('price_list.detail.price_breakdown.recommended_price')}
            </div>
            <div css={styles.pricePositive}>
              {formatCurrency(
                isPerSM
                  ? priceSums.recommendedPrice
                  : applyRounding(priceSums.recommendedPrice),
              )}
            </div>
          </div>
          {(priceSums.priceUpdate <= -0.1 ||
            priceSums.priceUpdate >= 0.1 ||
            isUpdateFrozen) && (
            <div>
              <div css={styles.description}>
                {isRent
                  ? t(
                      'price_list.detail.price_breakdown.rent_update',
                      'Rent update',
                    )
                  : t(
                      'price_list.detail.price_breakdown.price_update',
                      'Price update',
                    )}
              </div>
              <div className='flex gap-2 items-end justify-center w-full'>
                {priceSums.priceUpdate > 0 && (
                  <div css={styles.pricePositive}>
                    {priceSums.priceUpdate ? (
                      <CaretUpFilled css={styles.chevron} />
                    ) : null}{' '}
                    {`+ ${
                      isPercentage
                        ? formatPercent(roundedUpdate, { round: 1 })
                        : formatCurrency(
                            priceSums.priceUpdate + rounding,
                            false,
                            false,
                            false,
                            true,
                          )
                    }`}
                  </div>
                )}
                {priceSums.priceUpdate < -0.1 && (
                  <div css={styles.priceNegative}>
                    {priceSums.priceUpdate ? (
                      <CaretDownFilled css={styles.chevron} />
                    ) : null}
                    {`${
                      isPercentage
                        ? formatPercent(roundedUpdate, { round: 1 })
                        : formatCurrency(
                            priceSums.priceUpdate + rounding,
                            false,
                            false,
                            false,
                            true,
                          )
                    }`}
                  </div>
                )}
                <span>
                  {isUpdateFrozen && (
                    <InfoPopover
                      popoverProps={{
                        content: t(
                          'pricing.tooltip.frozen',
                          'The price of this unit was changed in the past month and we don’t recommend further changes.',
                        ),
                      }}
                    />
                  )}
                </span>
              </div>
            </div>
          )}
        </section>
      )}
    </Card>
  );
};
