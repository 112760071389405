/** @jsxImportSource @emotion/react */
import { FC, useMemo } from 'react';
import { TableProps, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRegionFormatting, useStoreState } from '../../../../hooks';
import { styles } from './styles';
import { FloorOverviewTableData, FloorOverviewTableProps } from './types';
import { getNumericSorter } from '../../../../utils';

enum FilterEnum {
  AVAILABLE,
  SOLD,
}

export const FloorOverviewTable: FC<FloorOverviewTableProps> = ({
  available,
  sold,
  filterValue,
  ...rest
}) => {
  const { t } = useTranslation();
  const { formatCurrency, calculateVatPrice, formatAreaUnits, areaUnit } = useRegionFormatting();
  const { profile } = useStoreState((state) => state.user);
  const columns: TableProps<FloorOverviewTableData>['columns'] = useMemo(
    () => [
      {
        title: t('enums.house_areas.floor', 'Floor'),
        dataIndex: 'floor',
        align: 'center',
        sorter: getNumericSorter('floor'),
      },
      {
        title: t('project.table.units', 'Units'),
        dataIndex: 'count',
        align: 'center',
        sorter: getNumericSorter('count'),
      },
      {
        title: t('project.table.interior_unit', 'Interior {{unit}}', {
          unit: areaUnit,
        }),
        dataIndex: 'average_floor_area',
        render: (value) => formatAreaUnits(value),
        align: 'center',
        sorter: getNumericSorter('average_floor_area'),
      },
      {
        title: t('project.table.price_per_unit', 'AVG Price per {{unit}}', {
          unit: areaUnit,
        }),
        dataIndex: 'average_price_per_sm',
        render: (value) => formatCurrency(calculateVatPrice(value || null, 120)),
        align: 'center',
        sorter: getNumericSorter('average_price_per_sm'),
      },
      {
        title: t('project.table.price', 'AVG Price'),
        dataIndex: 'average_price',
        render: (value) => formatCurrency(calculateVatPrice(value || null, 120)),
        align: 'center',
        sorter: getNumericSorter('average_price'),
      },
    ],
    [areaUnit, formatAreaUnits, formatCurrency, t, profile],
  );
  return (
    <Table<FloorOverviewTableData>
      css={styles.table}
      rowKey='floor'
      columns={columns}
      pagination={{ pageSize: 10 }}
      size='small'
      dataSource={filterValue === FilterEnum.AVAILABLE ? available : sold}
      {...rest}
    />
  );
};
