import { FC } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFlatLayout, useStoreState } from '../../../../hooks';
import { SOLD } from '../../../../styles/constants';
import { translateBlockChartValue } from '../../../../utils/utils';
import { getColumnOptions } from '../../../../utils/defaultChartConfig';
import { Availability } from '../../../../store/types';
import { useMarketDetails } from '../../../../hooks/useMarketDetails';

export interface Data {
  meanDaysOnMarket: number;
  month: string;
}
interface Props {
  isOverall: boolean;
  data: Data[];
}

export const DaysOnMarketChart: FC<Props> = ({ data, isOverall }) => {
  const { isRentals } = useMarketDetails();
  const { getLayout } = useFlatLayout();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { displayValues } = useStoreState((state) => state.filters);

  const translateValue = (value: string): string =>
    isOverall
      ? translateBlockChartValue(value, t, isRentals)
      : getLayout(value);
  const config: ColumnConfig = getColumnOptions({
    smooth: true,
    data: data.map((d) => ({ ...d, series: Availability.SOLD })),
    color: SOLD,
    seriesField: 'series',
    xField: 'month',
    yField: 'meanDaysOnMarket',
    label: displayValues
      ? {
          position: 'top',
          style: {
            fill: '#000000',
            opacity: 0.6,
            padding: 2,
          },
        }
      : undefined,
    tooltip: {
      formatter: (datum) => {
        const value = datum as Data;
        return {
          name: value.month,
          value: value.meanDaysOnMarket ?? '-',
        };
      },
      title: (title) =>
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          year: 'numeric',
        }),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item?.color
              }"></div>
              <div class="bmt-label">${
                isRentals ? t('enums.state.leased') : t('enums.state.sold')
              }:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
    xAxis: {
      label: {
        formatter: (value) => {
          const dateValue = new Date(value);
          return dateValue.toLocaleDateString(language, {
            month: 'long',
            year: 'numeric',
          });
        },
      },
      line: { style: { lineWidth: 2 } },
      tickLine: { style: { lineWidth: 2 } },
    },
    yAxis: {
      max: Math.max(...data.map((d) => d.meanDaysOnMarket)) * 1.1,
      label: {
        formatter: (value) => value,
      },
    },
    legend: {
      itemName: {
        formatter: translateValue,
      },
      style: {
        fontSize: 14,
      },
      marker: {
        symbol: 'circle',
        style: {
          r: 12,
        },
      },
    },
  });
  return data.length > 0 ? (
    <Column {...config} />
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
