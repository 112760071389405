import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button, Image, Tooltip } from 'antd';
import { Link, generatePath } from 'react-router-dom';
import {
  ArrowsPointingOutIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { PriceListTableData } from '../../../api/mockData/priceListData';
import styles from './DetailPreviewCard.module.css';
import { Paths } from '../../../routes/Paths';
import { ReactComponent as ExteriorIcon } from './icons/exteriorIcon.svg';
import { ReactComponent as FloorArea } from '../../../assets/project-card/floor-area.svg';
import { ReactComponent as LayoutIcon } from '../../../assets/icons/layout.svg';
import { ReactComponent as StackIcon } from './icons/stackIcon.svg';
import increaseIcon from './icons/increaseIcon.svg';
import decreaseIcon from './icons/decreaseIcon.svg';
import {
  useStoreState,
  useRegionFormatting,
  useFlatLayout,
} from '../../../hooks';
import { getSafeSuffix, roundUpdate } from '../../../utils/utils';
import { formatDifference, formatPercent } from '../../../utils';
import { AvailabilityBadge } from '../../AvailabilityBadge/AvailablityBadge';
import { Availability, ProjectType } from '../../../store/types';
import { InfoPopover } from '../../InfoPopover';

type Props = {
  onHide?: () => void;
  showDetailButton?: boolean;
  className?: string[];
  data?: PriceListTableData;
  demandCard?: boolean;
  topPriceChanges?: [string, number][];
};

type LabelProps = {
  title?: string | number | null;
  icon?: ReactNode;
  children?: ReactNode[];
  tooltipTitle?: string;
};

const orientationSides = [
  'S',
  'N',
  'W',
  'E',
  'SN',
  'SW',
  'SE',
  'NW',
  'NE',
  'WE',
];

const DetailLabel: FC<LabelProps> = ({ title, icon, children, tooltipTitle }) =>
  tooltipTitle ? (
    <Tooltip title={tooltipTitle}>
      <div className={styles.label}>
        {icon}
        {title}
        {children}
      </div>
    </Tooltip>
  ) : (
    <div className={styles.label}>
      {icon}
      {title}
      {children}
    </div>
  );

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const DetailPreviewCardUpdated: FC<Props> = ({
  showDetailButton = false,
  className,
  data,
  demandCard = false,
  topPriceChanges,
  onHide,
}) => {
  const [imageIndex, setImageIndex] = useState(0);
  const developerId = useStoreState(
    (state) => state.user.profile?.developer_id,
  );
  const { t } = useTranslation();
  const { formatAreaUnits, formatCurrency, applyRounding } =
    useRegionFormatting();
  const { getLayoutAbbr } = useFlatLayout();
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;

  useEffect(() => {
    if (data && imageIndex > data.images.length - 1) {
      setImageIndex(data.images.length - 1);
    }
  }, [imageIndex, data]);

  const handleChevron = useCallback(
    (direction: 'prev' | 'next'): void => {
      if (data && data.images) {
        const lastIndex = data.images.length - 1;
        if (direction === 'prev') {
          setImageIndex(imageIndex > 0 ? imageIndex - 1 : lastIndex);
        }
        if (direction === 'next') {
          setImageIndex(imageIndex < lastIndex ? imageIndex + 1 : 0);
        }
      }
    },
    [data, imageIndex],
  );

  const roundedUpdate = useMemo(
    () =>
      roundUpdate(
        data?.new_price ?? 0,
        data?.total_update ?? 0 * 100,
        data?.current_price ?? 0,
        applyRounding,
      ),
    [data],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'ArrowLeft') {
        handleChevron('prev');
      } else if (event.key === 'ArrowRight') {
        handleChevron('next');
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [imageIndex, handleChevron]);

  if (!data) {
    return <div>Not Found</div>;
  }

  const {
    availability,
    layout,
    floor_area,
    exterior_area,
    floor,
    internal_id,
    images,
    insights,
    current_price,
    new_price,
    price_difference,
    id,
    floor_count,
    is_update_frozen,
  } = data;

  const isHouse = !!floor_count;

  const detailUrl = generatePath(Paths.PRICE_LIST_DETAIL, { unitId: id });

  const currentPrice = formatCurrency(current_price) ?? 0;
  const recommendedPrice = formatCurrency(applyRounding(new_price)) ?? 0;
  const priceDifference = formatDifference(roundedUpdate * 100);

  return (
    <div className={clsx(styles.detailCard, className)}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          <Link
            to={detailUrl}
            className={styles.lgText}
            style={{ marginRight: '1.25rem' }}
          >
            <div>
              {t('price_list.detail.title', { id: null })} {internal_id}
            </div>
          </Link>
          <AvailabilityBadge unitAvailability={availability} />
        </div>
        {onHide && (
          <Button className={styles.iconBtn} onClick={() => onHide()}>
            <ChevronDoubleRightIcon className='bm-icon w-8' />
          </Button>
        )}
        {showDetailButton && (
          <Link to={detailUrl}>
            <ArrowsPointingOutIcon className='bm-icon w-8' />
          </Link>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.labelContainer}>
          <DetailLabel
            icon={<LayoutIcon color='e5e7eb' className={styles.icon} />}
            title={getLayoutAbbr(layout, true)}
            tooltipTitle={t('enums.house_parts.layout')}
          />
          <DetailLabel
            icon={<FloorArea color='e5e7eb' className={styles.icon} />}
            title={formatAreaUnits(floor_area)}
            tooltipTitle={t('enums.house_parts.floor_area')}
          />
          <DetailLabel
            icon={<ExteriorIcon color='e5e7eb' className={styles.icon} />}
            title={formatAreaUnits(exterior_area)}
            tooltipTitle={t(
              'market.reports.size_comparison.switch.exterior_area',
            )}
          />
          <DetailLabel
            icon={<StackIcon color='e5e7eb' className={styles.icon} />}
            title={isHouse ? floor_count : floor}
            tooltipTitle={t(
              `enums.house_parts.${isHouse ? 'floor_count' : 'floor'}`,
            )}
          />
        </div>
        {imageIndex < images.length && (
          <>
            <div className={styles.imagesWrapper}>
              {images.length > 1 && (
                <>
                  <ChevronLeftIcon
                    className={styles.chevronIconLeft}
                    onClick={() => handleChevron('prev')}
                  />
                  <ChevronRightIcon
                    className={styles.chevronIconRight}
                    onClick={() => handleChevron('next')}
                  />
                </>
              )}
              <Image
                preview={{
                  mask: false,
                }}
                src={`https://built-mind-internaldata.s3.eu-central-1.amazonaws.com/${developerId}/resources/images/flats/${getSafeSuffix(
                  images[imageIndex].toString(),
                )}`}
                alt='Floor plan'
                className={styles.image}
              />
            </div>
            {images.length > 1 && (
              <div className='flex w-full justify-center items-center space-x-2 px-6'>
                {images.map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div
                    onClick={() => setImageIndex(index)}
                    className={`w-full max-w-[80px] h-2 cursor-pointer ${
                      imageIndex === index ? 'bg-bmgray' : 'bg-bmlightgray'
                    }`}
                  />
                ))}
              </div>
            )}
          </>
        )}
        <div className={styles.pricesContainer}>
          <div className={styles.priceColumn}>
            <span className={styles.smallText}>
              {t(`detail_preview_card.${isRent ? 'rent' : 'price'}`)}
            </span>
            <div className={styles.price}>
              <span className={styles.lgText}>{currentPrice}</span>
            </div>
          </div>
          {availability === Availability.AVAILABLE && (
            <div className={styles.divider} />
          )}
          {availability === Availability.AVAILABLE && (
            <div className={styles.priceColumn}>
              {isRent ? (
                <span className={styles.smallText}>
                  {t('detail_preview_card.recommended_rent')}
                </span>
              ) : (
                <Tooltip
                  title={t('detail_preview_card.recommended_price_long')}
                >
                  <span className={styles.smallText}>
                    {t('detail_preview_card.recommended_price')}
                  </span>
                </Tooltip>
              )}
              <div className={styles.price}>
                <>
                  <span className={styles.lgText}>{recommendedPrice}</span>
                  <div className='flex gap-2 items-center justify-center'>
                    {priceDifference.includes('+') && (
                      <span className={styles.smallText}>
                        <img
                          src={increaseIcon}
                          className={styles.smallIcon}
                          alt='Decrease'
                        />
                        {priceDifference}
                      </span>
                    )}
                    {priceDifference.includes('-') && (
                      <span className={styles.smallText}>
                        <img
                          src={decreaseIcon}
                          className={styles.smallIcon}
                          alt='Decrease'
                        />
                        {priceDifference}
                      </span>
                    )}
                    <span>
                      {is_update_frozen && (
                        <InfoPopover
                          popoverProps={{
                            content: t(
                              'pricing.tooltip.frozen',
                              'The price of this unit was changed in the past month and we don’t recommend further changes.',
                            ),
                          }}
                        />
                      )}
                    </span>
                  </div>
                </>
              </div>
            </div>
          )}
        </div>
        {demandCard && topPriceChanges ? (
          <div className={styles.footerColumn}>
            <span className={styles.footerTitle}>
              {t('detail_preview_card.demand.title')}
            </span>
            {topPriceChanges.map(([name, priceChange]) => {
              const priceChangePercentage = `${(priceChange * 100).toFixed(
                1,
              )}%`;
              return orientationSides.includes(name) ? (
                <DetailLabel key={name}>
                  <span className={priceChange < 0 ? styles.decreaseText : ''}>
                    <img
                      src={priceChange > 0 ? increaseIcon : decreaseIcon}
                      className={styles.smallIcon}
                      alt='Increase'
                    />
                    {priceChangePercentage}
                  </span>
                  <span className={styles.footerValue}>
                    {t(`price_update.title.${name}`)}{' '}
                    {t('price_update.title.orientation')}
                  </span>
                </DetailLabel>
              ) : (
                <DetailLabel key={name}>
                  <span className={priceChange < 0 ? styles.decreaseText : ''}>
                    <img
                      src={priceChange > 0 ? increaseIcon : decreaseIcon}
                      className={styles.smallIcon}
                      alt='Increase'
                    />
                    {priceChangePercentage}
                  </span>
                  <span className={styles.footerValue}>
                    {t(`price_update.title.${name}`)}
                  </span>
                </DetailLabel>
              );
            })}
          </div>
        ) : (
          <div className={styles.footerColumn}>
            {!!insights.length ? (
              <>
                <span className={styles.footerTitle}>
                  {t('detail_preview_card.demand.title', 'Demand')}
                </span>
                <div className='flex flex-col gap-2'>
                  {insights.map(
                    (insight: { name: string; value: string | number }) => (
                      <div className='flex w-fit items center gap-2 rounded-[15px] border-[#BDBDBD] border-[1px] px-4 py-2'>
                        <img
                          src={
                            Number(insight.value) > 0
                              ? increaseIcon
                              : decreaseIcon
                          }
                          className='w-4'
                          alt='Decrease'
                        />
                        <span
                          className={clsx(
                            Number(insight.value) > 0
                              ? 'text-[#313B5E]'
                              : 'text-[#828282]',
                          )}
                        >
                          {formatPercent(Number(insight.value))}
                        </span>
                        <span className='pl-2 font-semibold'>
                          {t(`enums.house_parts.${insight.name}`)}
                        </span>
                      </div>
                    ),
                  )}
                </div>
              </>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};
