import { FC } from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { Unit } from '../../../api/mockData/priceListData';
import { AvailabilityBadge } from '../../AvailabilityBadge/AvailablityBadge';
import {
  useFlatLayout,
  useRegionFormatting,
  useStoreState,
} from '../../../hooks';
import { ProjectType } from '../../../store/types';

type Props = {
  position: { x: number; y: number };
  unit?: Unit;
};

export const UnitInfo: FC<Props> = ({ position, unit }) => {
  const { t } = useTranslation();
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  const { formatCurrency, calculateVatPrice, applyRounding, formatAreaUnits } =
    useRegionFormatting();
  const { getLayout } = useFlatLayout();

  if (!unit) {
    return null;
  }

  const {
    current_price,
    recommended_price,
    floor_area,
    exterior_area,
    layout,
  } = unit;

  const currentPrice = formatCurrency(current_price ?? 0);
  const recommendedPrice = formatCurrency(
    applyRounding(recommended_price ?? 0),
  );
  const floorArea = formatAreaUnits(floor_area);
  const exteriorArea = formatAreaUnits(exterior_area);

  const style: React.CSSProperties = {
    position: 'absolute',
    left: `${position.x}px`,
    top: `${position.y}px`,
    zIndex: 1000,
    pointerEvents: 'none', // Prevent blocking mouse interactions
  };

  return (
    <div style={style}>
      <Card className='!rounded-[20px]'>
        <div className='flex flex-col items-start justify-center min-w-[25rem]'>
          <div className='flex w-full justify-between items-center'>
            <span className='font-bold'>{unit.name}</span>
            <AvailabilityBadge unitAvailability={unit.availability} />
          </div>
          <div className='flex w-full justify-between items-center'>
            <span>{t('enums.house_parts.layout')}</span>
            <span className='font-bold'>{getLayout(`layout_${layout}`)}</span>
          </div>
          {floor_area && (
            <div className='flex w-full justify-between items-center'>
              <span>{t('enums.house_parts.floor_area')}</span>
              <span className='font-bold'>{floorArea}</span>
            </div>
          )}
          {exterior_area && (
            <div className='flex w-full justify-between items-center'>
              <span>{t('market.comparison.exterior_area')}</span>
              <span className='font-bold'>{exteriorArea}</span>
            </div>
          )}
          <div className='flex w-full justify-between items-center'>
            <span>
              {unit.recommended_price
                ? t('detail_preview_card.recommended_price')
                : t(`detail_preview_card.${isRent ? 'rent' : 'price'}`)}
            </span>
            <span className='font-bold'>
              {recommendedPrice || currentPrice}
            </span>
          </div>
        </div>
      </Card>
    </div>
  );
};
