/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { FC, useCallback, useMemo } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react/macro';
import debounce from 'lodash.debounce';
import { useStoreActions, useStoreState } from '../../../hooks';
import { sortByString } from '../../../utils/utils';

const styles = css({ width: '100%' });

export const CompetitorSelect: FC = () => {
  const { t } = useTranslation();
  const selectedCompetitorsRedux = useStoreState(
    (state) => state.market.selectedCompetitors,
  );
  const competitors = useStoreState(
    (state) => state.market.dashboardOverviewData?.competitors,
  );
  const allCompetitors = useMemo(() => {
    if (!competitors) return [];
    return competitors
      .sort((a, b) => sortByString(a.name, b.name))
      .map(({ id, name }) => ({ label: name, value: id }));
  }, [competitors]);

  const setSelectedCompetitorsRedux = useStoreActions(
    (actions) => actions.market.setSelectedCompetitors,
  );
  const debouncedSetSelectedCompetitorsRedux = useCallback(
    debounce(setSelectedCompetitorsRedux, 1500),
    [],
  );

  const handleChange = (value: number[]): void => {
    const finalVal = value;
    if (finalVal.length < 10) {
      debouncedSetSelectedCompetitorsRedux(finalVal);
    }
  };

  const handleClear = (): void => {
    setSelectedCompetitorsRedux([]);
  };

  return (
    <Select<number[]>
      css={styles}
      value={selectedCompetitorsRedux}
      onChange={handleChange}
      placeholder={t('market.reports.add_competitors', 'Add Competitors')}
      mode='multiple'
      allowClear
      optionFilterProp='label'
      options={allCompetitors}
      onClear={handleClear}
      maxTagCount={5}
    />
  );
};
