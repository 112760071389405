/** @jsxImportSource @emotion/react */
import { FC, useEffect, useRef, useState } from 'react';
import { Segmented, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../../../hooks';
import { useFilter } from '../useFilter';
import { styles } from '../styles';
import { Data, SizeComparisonChart } from './SizeComparisonChart';
import { ChartCard } from '../../../ChartCard';
import {
  getAvailableUnitsLayout,
  getDisappearedUnits7DaysLayout,
} from '../../../../api/secondary';
import { Availability } from '../../../../store/types';
import { getReportExportFileName } from '../../../../utils/utils';
import { CardPopover } from '../CardPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';

enum FilterEnum {
  FLOOR_AREA,
  EXTERIOR_AREA,
}

export const SizeComparison: FC = () => {
  const [data, setData] = useState<Data[]>([]);
  const [isLoading, setLoading] = useState(true);
  const reportId = useStoreState(
    (state) => state.secondaryMarket.activeReportId,
  );
  const { paramsSM } = useStoreState((state) => state.filters);
  const { t } = useTranslation();
  const { filterValue, handleChange } = useFilter(FilterEnum.FLOOR_AREA);
  const pngRef = useRef(null);

  useEffect(() => {
    if (reportId && paramsSM?.date) {
      setLoading(true);
      const payload = {
        reportId,
        date: paramsSM.date,
      };
      Promise.all([
        getAvailableUnitsLayout(payload),
        getDisappearedUnits7DaysLayout({
          reportId,
          startDate: paramsSM.date,
          endDate: paramsSM.date,
        }),
      ])
        .then((response) => {
          const [available, disappeared] = response;
          const dateSpecificDisappeared = disappeared.find(
            (d) => d.createdDate === paramsSM.date,
          );
          setData([
            ...available.availableUnitsLayout.map((d) => ({
              value: d.interiorSm,
              layout: `layout_${d.countOfRegularRooms}`,
              series: Availability.AVAILABLE,
            })),
            ...(dateSpecificDisappeared?.disappearedLayout || []).map((d) => ({
              value: d.interiorSm,
              layout: `layout_${d.countOfRegularRooms}`,
              series: Availability.SOLD,
            })),
          ]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [reportId, paramsSM]);

  const fileName = getReportExportFileName(
    paramsSM?.name || 'report',
    t('market.reports.size_comparison.title'),
  );

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
      <ChartCard
        title={t('market.reports.size_comparison.title', 'Size Comparison')}
        subtitle={t(
          'market.reports.size_comparison.subtitle',
          'The comparison of the average interior and exterior area of units in the selected projects.',
        )}
        extra={
          !isLoading && (
            <CardPopover
              showValues
              onDownloadPNG={() => downloadPNG(fileName, pngRef)}
            />
          )
        }
        chart={
          // eslint-disable-next-line no-nested-ternary
          isLoading ? (
            <Spin css={styles.center} spinning />
          ) : data.length > 0 ? (
            <SizeComparisonChart data={data} />
          ) : null
        }
        controls={
          <Segmented
            value={filterValue}
            options={[
              {
                value: FilterEnum.FLOOR_AREA,
                label: t(
                  'market.size_comparison.switch.floor_area',
                  'Floor Area',
                ),
              },
            ]}
            onChange={handleChange}
          />
        }
      />
    </div>
  );
};
