import { Action, action, thunk, Thunk } from 'easy-peasy';
import {
  getPricingDashboardData,
  updateSelectedCompetitors,
} from '../api';
import { StoreModel } from './types';
import {
  PricingDashboardData,
} from '../api/mockData/dashboardData';
import {
  PricingDashboardCompetitorsDataParameters,
  PricingDashboardDataParameters,
} from '../api/types';
import { logError } from '../utils/utils';

export interface DashboardStore {
  dashboardData?: PricingDashboardData;
  updateData?: any;
  dashboardLoading: boolean;
  includeReservations: boolean;
  showAvailableUnits: boolean;
  showSoldUnits: boolean;
  updatingCompetitors: boolean;
  positioningActiveItems: string[];
  setPositioningActiveItems: Action<DashboardStore, string[]>;
  setDashboardLoading: Action<DashboardStore, boolean>;
  setUpdateData: Action<DashboardStore, any>;
  setDashboardData: Action<DashboardStore, PricingDashboardData>;
  setIncludeReservations: Action<DashboardStore, boolean>;
  setShowAvailableUnits: Action<DashboardStore, boolean>;
  setShowSoldUnits: Action<DashboardStore, boolean>;
  setUpdatingCompetitors: Action<DashboardStore, boolean>;
  fetchDashboardData: Thunk<
    DashboardStore,
    PricingDashboardDataParameters,
    unknown,
    StoreModel
  >;
  fetchCompetitors: Thunk<
    DashboardStore,
    PricingDashboardDataParameters,
    unknown,
    StoreModel
  >;
  updateSelectedCompetitors: Thunk<
    DashboardStore,
    PricingDashboardCompetitorsDataParameters,
    unknown,
    StoreModel
  >;
}

export const dashboardStore: DashboardStore = {
  dashboardLoading: false,
  includeReservations: true,
  showAvailableUnits: true,
  showSoldUnits: true,
  updatingCompetitors: false,
  positioningActiveItems: [],
  setPositioningActiveItems: action((state, value) => {
    state.positioningActiveItems = value;
  }),
  setDashboardLoading: action((state, value) => {
    state.dashboardLoading = value;
  }),
  setUpdateData: action((state, value) => {
    state.updateData = value;
  }),
  setDashboardData: action((state, newData: PricingDashboardData) => {
    state.dashboardData = newData;
  }),
  setIncludeReservations: action((state, value) => {
    state.includeReservations = value;
  }),
  setShowAvailableUnits: action((state, value) => {
    state.showAvailableUnits = value;
  }),
  setShowSoldUnits: action((state, value) => {
    state.showSoldUnits = value;
  }),
  setUpdatingCompetitors: action((state, value) => {
    state.updatingCompetitors = value;
  }),
  fetchDashboardData: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
      dashboard: { setDashboardLoading },
    } = helpers.getStoreActions();
    setGlobalLoading(true);
    setDashboardLoading(true);
    try {
      const { data } = await getPricingDashboardData({
        ...payload,
      });
      actions.setDashboardData(data);
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
      setDashboardLoading(false);
      setIsLoaded(true);
    }
  }),
  updateSelectedCompetitors: thunk(async (actions, payload, helpers) => {
    actions.setUpdatingCompetitors(true);
    try {
      await updateSelectedCompetitors({ ...payload });
      await actions.fetchCompetitors({ projectId: payload.projectId });
    } catch (error) {
      logError(error);
    } finally {
      actions.setUpdatingCompetitors(false);
    }
  }),
  // TODO: this is only workaround to fetch competitors for now
  fetchCompetitors: thunk(async (actions, payload, helpers) => {
    try {
      const { data } = await getPricingDashboardData({
        ...payload,
      });
      actions.setDashboardData(data);
    } catch (error) {
      logError(error);
    }
  }),
};
