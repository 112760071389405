/* eslint-disable no-nested-ternary */
import { FC, useState, useEffect, useMemo } from 'react';
import { CircleF, CircleProps, PolylineF } from '@react-google-maps/api';
import { useStoreState } from '../../hooks';
import { Mode } from '../../store/types';

type CustomCircleProps = CircleProps & {
  name: string;
  circleCenter: google.maps.LatLng;
  circleRadius: number;
  onUpdate?: (circle: google.maps.Circle, name: string) => void;
  onDelete?: (name: string) => void;
  isHoveredGeometry?: boolean;
};

export const CustomCircle: FC<CustomCircleProps> = ({
  name,
  circleCenter,
  circleRadius,
  onUpdate,
  onDelete,
  isHoveredGeometry,
}) => {
  const { mode, mapControls } = useStoreState((state) => state.market);
  const [circleRef, setCircleRef] = useState<google.maps.Circle | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const isProjectsReadMode = mode === Mode.READ;
  const deleteMode = useMemo(() => mapControls === 'delete', [mapControls]);
  const edit = useMemo(() => mapControls === 'edit', [mapControls]);

  const circleOptions: google.maps.CircleOptions = {
    editable: mode !== Mode.READ && (isHovered || isEditing) && edit,
    fillColor: deleteMode && isHovered ? '#FF9A9A99' : '#5A72B1',
    fillOpacity: isProjectsReadMode
      ? 0.2
      : isHoveredGeometry
        ? 0.8
        : 0.5,
    strokeColor: isProjectsReadMode
      ? 'transparent'
      : deleteMode && isHovered
        ? '#EA5A5A'
        : '#5A72B1',
    draggable: edit,
  };

  // Generate dashed outline points
  const generateCirclePath = (center: google.maps.LatLngLiteral, radius: number): google.maps.LatLngLiteral[] => {
    const numPoints = 50; // More points for a smoother circle
    const path: google.maps.LatLngLiteral[] = [];
    const angleStep = (2 * Math.PI) / numPoints;

    for (let i = 0; i < numPoints; i++) {
      const angle = i * angleStep;
      const lat = center.lat + (radius / 111320) * Math.cos(angle);
      const lng =
        center.lng +
        (radius / (111320 * Math.cos((center.lat * Math.PI) / 180))) *
          Math.sin(angle);
      path.push({ lat, lng });
    }
    return path;
  };

  const dashedPath = useMemo(
    () => generateCirclePath(circleCenter as unknown as google.maps.LatLngLiteral, circleRadius),
    [circleCenter, circleRadius],
  );

  const dotSymbol = {
    path: 'M 0,-0.1 0,0.1',
    scale: 3,
  };

  const polylineOptions: google.maps.PolylineOptions = {
    strokeColor: '#313B5E',
    strokeOpacity: 1,
    strokeWeight: 0,
    icons: [
      {
        icon: dotSymbol,
        offset: '0',
        repeat: '6px',
      },
    ],
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (circleRef) {
      const onCircleChange = (): void => {
        if (onUpdate) {
          onUpdate(circleRef, name);
        }
      };

      const listeners = [
        google.maps.event.addListener(
          circleRef,
          'center_changed',
          onCircleChange,
        ),
        google.maps.event.addListener(
          circleRef,
          'radius_changed',
          onCircleChange,
        ),
        google.maps.event.addListener(circleRef, 'dragend', onCircleChange),
      ];

      return () => {
        listeners.forEach((listener) =>
          google.maps.event.removeListener(listener),
        );
      };
    }
  }, [circleRef, onUpdate, name]);

  const handleOnMouseDown = (): void => {
    setIsEditing(true);
  };

  const handleOnMouseUp = (): void => {
    setIsEditing(false);
  };

  const handleOnClick = (): void => {
    if (deleteMode && onDelete) {
      onDelete(name);
    }
  };

  return (
    <>
      <CircleF
        center={circleCenter}
        radius={circleRadius}
        options={circleOptions}
        onLoad={(circle) => setCircleRef(circle)}
        onMouseDown={handleOnMouseDown}
        onMouseUp={handleOnMouseUp}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        onClick={handleOnClick}
      />
      <PolylineF path={dashedPath} options={polylineOptions} />
    </>
  );
};
