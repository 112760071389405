import { FC, useEffect, useRef, useState } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';
import dayjs from 'dayjs';
import { TransformedProjectTableData } from '.';

type Props = {
  tableData: TransformedProjectTableData;
  dates: string[];
}

export const ProjectTable: FC<Props> = ({ tableData, dates }) => {
  const scrollRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(scrollRef || { current: null });
  const cellRef = useRef<HTMLDivElement>(null);
  const [scrollStep, setScrollStep] = useState(0);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [isGrabbing, setIsGrabbing] = useState(false);

  useEffect(() => {
    if (cellRef.current) {
      const cellWidth = cellRef.current.getBoundingClientRect().width;
      setScrollStep(cellWidth);
    }
  }, []);

  useEffect(() => {
    const element = scrollRef.current;

    if (!element) {
      return;
    }

    const nativeHandleWheel = (e: WheelEvent): void => {
      e.preventDefault();

      if (scrollRef.current && scrollStep > 0) {
        const scrollAmount = scrollStep;

        if (e.deltaY > 0) {
          scrollRef.current.scrollLeft += scrollAmount;
        } else {
          scrollRef.current.scrollLeft -= scrollAmount;
        }
      }
    };

    element.addEventListener('wheel', nativeHandleWheel, { passive: false });

    // eslint-disable-next-line consistent-return
    return () => {
      element.removeEventListener('wheel', nativeHandleWheel);
    };
  }, [scrollStep]);

  useEffect(() => {
    const element = scrollRef.current;
    const handleScroll = (): void => {
      if (scrollRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;

        // Check if at the start
        setIsAtStart(scrollLeft === 0);

        // Check if at the end
        setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
      }
    };

    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handlePaginate = (dir: 'left' | 'right'): void => {
    if (scrollRef.current) {
      const scrollAmount = scrollStep * 6;

      if (dir === 'right') {
        scrollRef.current.scrollLeft += scrollAmount;
      } else {
        scrollRef.current.scrollLeft -= scrollAmount;
      }
    }
  };

  return (
  <div className='relative flex items-center overflow-hidden'>
    {!isAtStart && <div className='absolute flex font-bold items-center top-3 text-2xl left-[18rem]' onClick={() => handlePaginate('left')}>{'<'}</div>}
    <div className='flex flex-col items-start h-full'>
      <span className='h-16 w-[21rem] border-b-2 border-bmverylightgrey' />
      {Object.keys(tableData).map((item) => (
        <span className='flex h-16 w-[21rem] items-center font-bold border-b border-bmverylightgrey'>{item}</span>
      ))}
    </div>
    <div
      className='flex flex-col items-start h-full overflow-x-scroll'
      {...events}
      ref={scrollRef}
    >
      <div className='flex'>
        {dates.map((item) => (
          <div key={item} ref={cellRef} className='flex items-center justify-center font-bold w-24 h-16 border-b-2 border-bmverylightgrey'>{dayjs(item).format('MM/YY')}</div>
        ))}
      </div>
      {Object.keys(tableData).map((td) => (
        <div className='flex'>{tableData[td].map((item) => <div className={`flex h-16 w-24 items-center justify-center border-b border-bmverylightgrey ${isGrabbing ? 'cursor-grabbing' : 'cursor-grab'}`} onMouseDown={() => setIsGrabbing(true)} onMouseUp={() => setIsGrabbing(false)}>{item.value || 0}</div>)}</div>
      ))}
    </div>
    <div className='flex font-bold self-start text-2xl py-3 pl-4 pr-7 justify-center' onClick={() => handlePaginate('right')}>{!isAtEnd ? '>' : ''}</div>
  </div>
  );
};
