/* eslint-disable no-nested-ternary */
import { FC, useMemo, useState } from 'react';
import { PolygonF, PolygonProps, PolylineF } from '@react-google-maps/api';
import { useLocation } from 'react-router-dom';
import { useStoreState } from '../../hooks';
import { Mode } from '../../store/types';
import { LatLng } from '../../utils/types';

type CustomPolygonProps = PolygonProps & {
  name: string;
  paths: LatLng[];
  onUpdate?: (polygon: google.maps.Polygon, name: string) => void;
  onDelete?: (name: string) => void;
  isHoveredGeometry?: boolean;
};

export const CustomPolygon: FC<CustomPolygonProps> = ({
  name,
  paths,
  onUpdate,
  onDelete,
  isHoveredGeometry,
}) => {
  const { mode, mapControls } = useStoreState((state) => state.market);
  const [polygonRef, setPolygonRef] = useState<google.maps.Polygon | null>(
    null,
  );
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isPolygonBeingEdited, setIsPolygonBeingEdited] =
    useState<boolean>(false);

  const deleteMode = useMemo(() => mapControls === 'delete', [mapControls]);
  const edit = useMemo(() => mapControls === 'edit', [mapControls]);

  const isProjectsReadMode = mode === Mode.READ;

  // Polygon options
  const polygonOptions: google.maps.PolygonOptions = {
    editable: mode !== Mode.READ && (isHovered || isEditing) && edit,
    fillColor: isProjectsReadMode
    ? '#5A72B1'
    : deleteMode && isHovered
        ? '#FF9A9A99'
        : '#5A72B1',
    fillOpacity: isProjectsReadMode
      ? 0.2
      : isHoveredGeometry
        ? 0.8
        : 0.5,
    strokeColor: deleteMode && isHovered
        ? '#EA5A5A'
        : '#5A72B1',
    strokeOpacity: isProjectsReadMode ? 0 : 1,
  };

  const dotSymbol = {
    path: 'M 0,-0.1 0,0.1',
    scale: 3,
  };

  // Polyline options
  const polylineOptions: google.maps.PolylineOptions = {
    strokeColor: '#313B5E',
    strokeOpacity: 1,
    strokeWeight: 0,
    icons: [
      {
        icon: dotSymbol,
        offset: '0',
        repeat: '6px',
      },
    ],
  };

  const handlePolygonClick = (): void => {
    if (deleteMode && polygonRef) {
      if (onDelete) {
        onDelete(name);
      }
    }
  };

  const handleOnMouseDown = (e: google.maps.MapMouseEvent): void => {
    const originalPath = [e.latLng?.lat(), e.latLng?.lng()];
    const parsedPaths = paths?.map((path) => [path.lat, path.lng]);
    if (originalPath[0] && originalPath[1]) {
      const coordinateChanged = (parsedPaths as number[][]).some(
        (path) => path[0] === originalPath[0] && path[1] === originalPath[1],
      );
      if (coordinateChanged) {
        setIsPolygonBeingEdited(true);
        setIsEditing(true);
      }
    }
  };

  const handleOnMouseUp = (): void => {
    const polygonPaths = polygonRef?.getPath()?.getArray();
    const coordinateAdded = (polygonPaths || []).length > (paths || []).length;

    if (onUpdate && polygonRef && (isPolygonBeingEdited || coordinateAdded)) {
      onUpdate(polygonRef, name);
      setIsPolygonBeingEdited(false);
    }

    setIsEditing(false);
  };

  return (
    <>
      <PolylineF
        options={polylineOptions}
        path={paths}
        onMouseDown={handleOnMouseDown}
        onMouseUp={handleOnMouseUp}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        onClick={handlePolygonClick}
      />
      <PolygonF
        options={polygonOptions}
        paths={paths}
        onLoad={(initialPolygon) => setPolygonRef(initialPolygon)}
        onMouseDown={handleOnMouseDown}
        onMouseUp={handleOnMouseUp}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        onClick={handlePolygonClick}
      />
    </>
  );
};
