import clsx from 'clsx';
import React, { useMemo, useCallback, useEffect, FC } from 'react';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { State } from '../../../api/enums';
import { useRegionFormatting } from '../../../hooks/useRegionFormatting';
import { AnalyticsPopover } from './AnalyticsPopover';

const PRICE_CHANGE_THRESHOLD = 0.075;
const ACTIVITY_CHANGE_THRESHOLD_LOW = 0.5;
const ACTIVITY_CHANGE_THRESHOLD_MID = 1.5;
const ACTIVITY_CHANGE_THRESHOLD_HIGH = 2.5;
const POINT_ONE_PERCENT = 0.001;

const AnalyticValues: FC<{
  uniqueSessions: number;
  averageDurationSeconds: number;
}> = ({
  uniqueSessions,
  averageDurationSeconds,
}: {
  uniqueSessions: number;
  averageDurationSeconds: number;
}) => (
  <Popover
    placement='top'
    content={
      <AnalyticsPopover
        uniqueClicks={uniqueSessions}
        averageSessionLength={averageDurationSeconds}
      />
    }
  >
    <div className='flat-detail-row'>{uniqueSessions}</div>
    <div className='flat-detail-row'>{averageDurationSeconds}</div>
  </Popover>
);

const AnalyticLabels: FC<{
  uniqueSessions: number;
  averageDurationSeconds: number;
}> = ({
  uniqueSessions,
  averageDurationSeconds,
}: {
  uniqueSessions: number;
  averageDurationSeconds: number;
}) => {
  const { t } = useTranslation();

  return (
    <Popover
      placement='top'
      content={
        <AnalyticsPopover
          uniqueClicks={uniqueSessions}
          averageSessionLength={averageDurationSeconds}
        />
      }
    >
      <div className='flat-detail-row light'>
        {t('price_list.hints.google.clicks', 'Clicks')}
      </div>
      <div className='flat-detail-row light'>
        {t('price_list.hints.google.length', 'Length')}
      </div>
    </Popover>
  );
};

export const FlatDetail = React.memo(
  ({
    viewType,
    layoutAbbreviation,
    availability,
    newPrice,
    floorArea,
    currentPrice,
    priceChange,
    isSelected,
    isDisabled,
    internalId,
    id,
    uniqueSessions,
    averageDurationSeconds,
    googleAnalyticsNormalized,
    onClick,
  }: {
    viewType: 'heatmap' | 'grid' | 'analytics';
    layoutAbbreviation: string;
    availability: string;
    floorArea: number;
    newPrice: number;
    priceChange: number;
    currentPrice: number;
    internalId: string;
    isSelected: boolean;
    isDisabled: boolean;
    id: number;
    uniqueSessions: number;
    averageDurationSeconds: number;
    googleAnalyticsNormalized: number;
    onClick: () => void;
  }) => {
    const { t } = useTranslation();
    const { formatCurrency } = useRegionFormatting();

    const didPriceChange = Math.abs(priceChange) >= POINT_ONE_PERCENT;
    const isPriceInMillions = useCallback((price: number) => price >= 1e6, []);
    const isPriceInThousands = useCallback(
      (price: number) => price < 1e6 && price >= 1e3,
      [],
    );

    const transparentStyle = useMemo(() => {
      const isPriceChangeLow =
        didPriceChange && Math.abs(priceChange) / PRICE_CHANGE_THRESHOLD < 1;
      const transparency = isPriceChangeLow
        ? 1.0 - Math.abs(priceChange) / PRICE_CHANGE_THRESHOLD
        : 0.0;
      const isAnalyticsActivityHigh =
        googleAnalyticsNormalized > ACTIVITY_CHANGE_THRESHOLD_MID;
      const analyticsTransparency = isAnalyticsActivityHigh
        ? 1 -
          (googleAnalyticsNormalized - ACTIVITY_CHANGE_THRESHOLD_MID) /
            (5 - ACTIVITY_CHANGE_THRESHOLD_MID)
        : googleAnalyticsNormalized / ACTIVITY_CHANGE_THRESHOLD_MID;
      return {
        backgroundColor: `color-mix(in srgb, var(--bg), transparent ${Math.round(
          viewType === 'heatmap'
            ? transparency * 100
            : analyticsTransparency * 100,
        )}%)`,
      };
    }, [didPriceChange, priceChange, googleAnalyticsNormalized]);

    const className = useMemo(() => {
      if (isDisabled) {
        return clsx('flat', 'detail', 'disabled', { selected: isSelected });
      }

      if (availability === State.SOLD) {
        return clsx('flat', 'detail cursor-pointer', 'availability', 'sold', {
          selected: isSelected,
        });
      }

      if (viewType === 'heatmap') {
        return clsx('flat', 'detail cursor-pointer', 'price', {
          increasing: priceChange > PRICE_CHANGE_THRESHOLD,
          'increasing-low':
            priceChange >= POINT_ONE_PERCENT &&
            priceChange <= PRICE_CHANGE_THRESHOLD,
          decreasing: priceChange < -PRICE_CHANGE_THRESHOLD,
          stable: !didPriceChange,
          'decreasing-low':
            priceChange <= -POINT_ONE_PERCENT &&
            priceChange >= -PRICE_CHANGE_THRESHOLD,
          selected: isSelected,
        });
      }

      if (viewType === 'analytics') {
        return clsx('flat', 'detail cursor-pointer', 'analytics', {
          'decreasing-low':
            googleAnalyticsNormalized < ACTIVITY_CHANGE_THRESHOLD_LOW,
          decreasing:
            googleAnalyticsNormalized >= ACTIVITY_CHANGE_THRESHOLD_LOW &&
            googleAnalyticsNormalized <= ACTIVITY_CHANGE_THRESHOLD_MID,
          'increasing-low':
            googleAnalyticsNormalized > ACTIVITY_CHANGE_THRESHOLD_MID &&
            googleAnalyticsNormalized <= ACTIVITY_CHANGE_THRESHOLD_HIGH,
          increasing:
            googleAnalyticsNormalized > ACTIVITY_CHANGE_THRESHOLD_HIGH,
          selected: isSelected,
        });
      }

      return clsx('flat', 'detail cursor-pointer', 'availability', {
        sold: availability === State.SOLD,
        reserved: availability === State.RESERVED,
        available: availability === State.AVAILABLE,
        selected: isSelected,
      });
    }, [
      isDisabled,
      viewType,
      didPriceChange,
      priceChange,
      availability,
      isSelected,
    ]);

    return (
      <div
        key={id}
        role='presentation'
        className={className}
        style={
          (viewType === 'heatmap' || viewType === 'analytics') &&
          availability !== State.SOLD
            ? transparentStyle
            : { opacity: viewType === 'analytics' ? 0.5 : 1 }
        }
        onClick={onClick}
      >
        <div
          style={{ marginRight: '8px', minWidth: '50%', textAlign: 'right' }}
        >
          <div
            className={clsx(
              'flat-detail-row',
              viewType === 'analytics' ? 'light' : '',
            )}
          >
            {internalId}
          </div>
          {viewType === 'analytics' ? (
            <AnalyticLabels
              uniqueSessions={uniqueSessions}
              averageDurationSeconds={averageDurationSeconds}
            />
          ) : (
            <>
              <div className='flat-detail-row'>
                {availability === State.RESERVED && viewType === 'heatmap' ? (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-evenly' }}
                  >
                    <Popover
                      placement='top'
                      content={t('enums.state.reserved', 'Reserved')}
                    >
                      <div
                        className={clsx('reserved-icon', {
                          disabled: isDisabled,
                        })}
                      >
                        R
                      </div>
                    </Popover>
                    {layoutAbbreviation}
                  </div>
                ) : (
                  layoutAbbreviation
                )}
              </div>
              <div className='flat-detail-row'>
                {floorArea.toFixed(1)} m
                <sup style={{ verticalAlign: 'top', lineHeight: '18px' }}>
                  2
                </sup>
              </div>
            </>
          )}
        </div>
        <div
          className={clsx('flat-detail-divider', { disabled: isDisabled })}
        />
        <div style={{ marginLeft: '8px', minWidth: '50%', textAlign: 'left' }}>
          <div
            className={clsx(
              'flat-detail-row',
              viewType === 'analytics' ? 'light' : '',
            )}
          >
            {didPriceChange && <>{(priceChange * 100).toFixed(1)} %</>}
          </div>
          {viewType === 'analytics' ? (
            <AnalyticValues
              uniqueSessions={uniqueSessions}
              averageDurationSeconds={averageDurationSeconds}
            />
          ) : (
            <>
              <div className='flat-detail-row light'>
                {didPriceChange ? (
                  <>
                    {formatCurrency(
                      newPrice,
                      undefined,
                      isPriceInMillions(newPrice),
                      isPriceInThousands(newPrice),
                    )}{' '}
                  </>
                ) : (
                  ' '
                )}
              </div>
              <div className='flat-detail-row light current-price'>
                {formatCurrency(
                  currentPrice,
                  undefined,
                  isPriceInMillions(currentPrice),
                  isPriceInThousands(currentPrice),
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  },
);
