import { PricePerSmCalcType } from '../components/TopBar/SettingsPopover';
import {
  DailyNewsData,
  DailyNewsPartialData,
  GpsDailyNewsData,
} from '../store/dailyNews';
import { Availability } from '../store/types';
import { MultiPolygonalSchema } from './secondaryClient';
import {
  BuildingConditionEnum,
  CountryEnum,
  CurrencyEnum,
  LanguageEnum,
  MarketDashboardBlockType,
  MarketDashboardTemplateEnum,
  MarketTypeEnum,
  MaterialQuality,
  MetricSystemEnum,
  Permit,
  PricePerSMCalcEnum,
  ProjectTypeEnum,
} from './enums';
import { SimplifiedProjects } from '../store/market';
import {
  PercentageChange,
  UpdateDetails,
} from './mockData/priceUpdateDataUpdated';

export interface LoginValues {
  email: string;
  password: string;
}
export interface LoginResponse {
  access_token: string;
}
export interface DashboardDataParameters {
  projectId: number;
  phaseId: number;
  date: string;
  price_per_sm_calculation?: PricePerSmCalcType;
}

export interface PricingDashboardCompetitorsDataParameters {
  projectId: number;
  competitors: number[];
}

export interface PricingDashboardDataParameters {
  projectId: number;
}
export interface MarketDashboardDataParameters {
  developerId: number;
  projectId: number;
  phaseId: number;
  date: string;
}
export interface Profile {
  developer_id: number;
  username: string;
  localization: string; // deprecated
  market_csv_enabled?: boolean;
  dashboard_enabled?: boolean;
  prediction_availability?: string[];
  language: LanguageEnum;
  currency: CurrencyEnum;
  system_of_measurement: MetricSystemEnum;
  price_per_sm_calculation: PricePerSMCalcEnum;
  country: CountryEnum;
  VAT_included: boolean;
  reserved_as_sold: boolean;
  price_rounding: string;
  market_enabled?: boolean;
  daily_news_enabled: boolean;
  country_iso: string;
  sub: string;
  reports_hierarchy?: string;
}
export interface URLResponse {
  upload_url: string;
}
export interface CreateMarketDashboardParameters {
  // city: string[];
  included_project_ids: number[];
  remaining_project_ids: number[];
  dashboard_name: string;
  geometry: MultiPolygonalSchema | null;
  include_new_projects: boolean;
  markets: string[] | null;
}

export interface IncludedTypesCreateMarketDashboard {
  included_unit_types: string[];
  included_construction_types: string[];
}

export interface DeleteSubscribedEmailsParameters {
  developerId: number;
  emails: string[];
}

export interface UpdateSalesTargets {
  sale_target_id: number;
  project_id: number;
  phase_id: number;
  target_type: 'units' | 'floor_area' | 'revenue';
  target_value: number;
  mandatory: boolean;
  target_month: string;
}

export interface UpdateSalesPlan {
  project_id: number;
  phase_id: number;
  sales_data: {
    month: string;
    units_sold: number;
  }[];
}

export interface UpdateSalesPlanDate {
  project_id: number;
  phase_id: number;
  date_end_sale: string;
}

export interface CreateSalesTarget {
  project_id: number;
  phase_id: number;
  target_type: 'units' | 'floor_area' | 'revenue';
  target_value: number;
  mandatory: boolean;
  target_month: string;
}

export interface DeleteSalesTarget {
  sale_target_id: number;
}

export interface SecondaryDashboardParameters {
  user_id: string;
  country: 'CZ' | 'SK';
  name: string;
  geometry: MultiPolygonalSchema | null;
  market_types: MarketTypeEnum[];
  building_conditions: BuildingConditionEnum[];
  number_of_days?: number;
}

export interface CreateSecondaryDashboardParameters
  extends Omit<SecondaryDashboardParameters, 'market_types'> {
  geometry: MultiPolygonalSchema;
  market_type: MarketTypeEnum;
  building_conditions: BuildingConditionEnum[];
}

export interface UpdateMarketDashboardParameters {
  included_project_ids: number[];
  remaining_project_ids: number[];
  dashboard_name: string;
  dashboard_id: number;
  geometry: MultiPolygonalSchema | null;
  include_new_projects: boolean;
}

export interface UpdateSecondaryDashboardParameters {
  user_id: string;
  name: number[];
  geometry: MultiPolygonalSchema | null;
  market_type: MarketTypeEnum;
}

export interface Materials {
  air_conditioning: boolean | null;
  cooling_ceilings: boolean | null;
  exterior_blinds: boolean | null;
  floors: string | null;
  heating: string | null;
  overall_quality: MaterialQuality | null;
  partition_walls: string | null;
  smart_home: boolean | null;
  windows: string | null;
}
export interface PriceList {
  id: string;
  layout: string;
  floor: number | null;
  availability: Availability;
  price: number | null;
  price_per_sm: number | null;
  floor_area: number;
  total_area: number;
  exterior_area: number | null;
  equivalent_area: number | null;
  baths: number;
  garages: number;
  building: string;
  category: string;
  DOM: number | null;
  orientation: string;
  first_seen: string;
  url: string;
  original_price: number | null;
  original_price_per_sm?: number | null;
}

export enum ProjectPhase {
  PLANNED = 'Planned',
  DEVELOPMENT = 'Development',
  COMPLETED = 'Completed',
  ARCHIVED = 'Archived',
  NONE = '',
}

interface RatingType {
  type: string;
  rating_value: number;
  rating_badge: string;
}
export interface MarketProject {
  address: string;
  available_units: number;
  avg_price: number | null;
  avg_price_per_sm?: number | null;
  cellar_price: number | null;
  cellar_price_per_sm: number | null;
  cellar_included: boolean | null;
  date_sale_start: string | null;
  date_sale_completion: string | null;
  date_construction_start: string | null;
  date_construction_completion: string | null;
  developer: string;
  district: string;
  gps_latitude: number;
  gps_longitude: number;
  layouts: string[];
  materials: Materials;
  max_price_per_sm: number | null;
  min_price_per_sm: number | null;
  municipality: string;
  parking_indoor_price: number | null;
  parking_outdoor_price: number | null;
  parking_included: boolean | null;
  payment_construction: number | null;
  payment_contract: number | null;
  payment_occupancy: number | null;
  permit_regular: Permit | null;
  price_list: PriceList[];
  project_id: number;
  project_name: string;
  project_url: string | null;
  public_transport_to_center: number | null;
  ride_to_center: number | null;
  sold_units: number;
  total_number_of_units: number | null;
  unavailable_units: {
    avg_price: number;
    avg_price_per_sm: number;
  };
  amenities: number;
  amenities_detail: string;
  schools: number;
  schools_detail: string;
  city: string;
  baths: number[];
  garages: number[];
  cooperative_housing: boolean;
  project_phase: ProjectPhase;
  ratings: RatingType[];
  building_use?: string;
  using_total_area?: boolean;
  floor_coefficient?: number;
  image_url?: string;
  unit_type: ProjectTypeEnum;
  renovation: boolean;
}
export interface MarketDashboardSettings {
  name: string;
  compared_project_id?: number;
  areas: string[];
  projects: SimplifiedProjects[];
  id?: number;
}
export interface GetMarketDashboardResponse {
  content: MarketDashboardSettings;
  dashboard_id: number;
}
export interface GetPriceListDataParams {
  projectId: number;
  date?: string;
  price_per_sm_calculation?: PricePerSmCalcType;
}

export interface GetPricingPriceListDataParams {
  projectId: number;
}

export interface GetPriceListUpdateDataParamsUpdated {
  projectId: number;
}

export interface UpdatePriceListUpdateDataParams {
  projectId: number;
  disabled_attributes: Partial<UpdateDetails>[];
  percentage_change: PercentageChange[];
}

export interface GetPriceListUpdateDataParams {
  price_list_update_id: number;
}

export interface MapTableRow {
  project_id: number;
  project_name: string;
  gps_latitude: number;
  gps_longitude: number;
}
export interface MapResponse {
  cities: string[];
  map: MapTableRow[];
  geometry: MultiPolygonalSchema | null;
}
export interface MarketShareData {
  project_name: string;
  internal_project_id: string;
  project_id: number;
  count: number;
  percentage: number;
  availability: Availability;
}
export interface MarketShareSaleSpeedData {
  layout: string;
  date: string;
  availability: Availability;
  value: number;
  project_id: number;
  project_name?: string;
  developer_id: number;
  developer_name: string;
  district: string;
  count: number;
}
export interface OverviewDataResponse {
  included_projects_statistics: MarketProject[];
  remaining_projects_statistics: MarketProject[];
}
export interface PriceComparisonData {
  layout: string;
  availability: string;
  market: {
    price: number;
    price_per_sm: number;
    floor_area?: number;
    exterior_area?: number;
    count: number;
  };
  series: string;
  project_name: string;
}
export interface PriceHistoryData {
  date: string;
  availability: string;
  avg_price: number | null;
  layout: string;
  series: string | number;
  project_name: string;
}

export interface PriceHistoryPrimaryData {
  date: string;
  availability: string;
  avg_price: number | null;
  flat_prices_price: number | null;
  layout: string;
  series: string | number;
  project_name: string;
}
export interface SaleSpeedData {
  date: string;
  layout: string | null;
  series: string | number;
  project_name: string;
  project_id?: number;
  count: number;
  counts_by_type: {
    [key: string]: number;
  };
  availability: string;
  value: number;
}
interface LayoutMetric {
  layout: string;
  total: number;
  average_interior: number;
  average_price_per_sm: number;
  average_price: number;
}

export interface DemandDataUnitList {
  project_name: string;
  developer: string;
  address: string;
  id: string;
  layout: string;
  floor: number | null;
  current_availability: Availability;
  price: number | null;
  price_per_sm: number | null;
  floor_area: number;
  total_area: number;
  exterior_area: number | null;
  equivalent_area: number | null;
  baths: number;
  garages: number;
  category: string;
  building: string;
  orientation: string;
  DOM: number;
  first_seen: string;
  parking_indoor_price: number;
  parking_outdoor_price: number;
  url: string;
  original_price: number | null;
  change: string;
  change_date: string;
  sale_type: string;
}

export interface SaleSpeedDetailData {
  date: string;
  total_units: number;
  average_price_per_sm: number;
  average_price: number;
  average_interior: number;
  growth_total: number;
  growth_price_per_sm: number;
  metrics: LayoutMetric[];
  units: DemandDataUnitList[];
}

interface LayoutMetricWithHistory {
  layout: string;
  total: {
    old: number;
    current: number;
    change: number;
  };
  average_interior: {
    old: number;
    current: number;
    change: number;
  };
  average_price_per_sm: {
    old: number;
    current: number;
    change: number;
  };
  average_price: {
    old: number;
    current: number;
    change: number;
  };
}

export interface SupplyDataUnitList {
  project_name: string;
  address: string;
  id: string;
  layout: string;
  floor: number | null;
  availability: Availability;
  price: number | null;
  price_per_sm: number | null;
  floor_area: number;
  total_area: number;
  exterior_area: number | null;
  equivalent_area: number | null;
  baths: number;
  garages: number;
}

export interface SupplyData {
  date: string;
  total_units: {
    old: number;
    current: number;
    change: number;
  };
  average_price_per_sm: {
    old: number;
    current: number;
    change: number;
  };
  average_price: {
    old: number;
    current: number;
    change: number;
  };
  average_interior: {
    old: number;
    current: number;
    change: number;
  };
  metrics: LayoutMetricWithHistory[];
  units: SupplyDataUnitList[];
}

export interface DemandData {
  date: string;
  total_units: {
    old: number;
    current: number;
    change: number;
  };
  average_price_per_sm: {
    old: number;
    current: number;
    change: number;
  };
  average_price: {
    old: number;
    current: number;
    change: number;
  };
  average_interior: {
    old: number;
    current: number;
    change: number;
  };
  metrics: LayoutMetricWithHistory[];
  units: DemandDataUnitList[];
}

export interface SupplyDataResponse {
  reserved_available: SupplyData;
  reserved_sold: SupplyData;
}
export interface DemandDataResponse {
  reserved_available: DemandData;
  reserved_sold: DemandData;
}
export interface SupplyHistoryOverallData {
  date: string;
  available: number;
  reserved: number;
}
export interface SupplyHistoryNewData {
  date: string;
  new: number;
}
export interface SupplyHistoryDataResponse {
  overall: SupplyHistoryOverallData[];
  under_construction: SupplyHistoryOverallData[];
  new: SupplyHistoryNewData[];
}

export interface SaleDetailData {
  category: string;
  state: string;
  value: number;
}

export interface SaleDetailDataResponse {
  data: SaleDetailData[];
}
export interface FloorOverviewData {
  floor: number;
  average_floor_area: number;
  average_price_per_sm: number;
  average_price: number;
  count: number;
}
export interface FloorOverviewResponse {
  reserved_available: {
    available: FloorOverviewData[];
    sold: FloorOverviewData[];
  };
  reserved_sold: {
    available: FloorOverviewData[];
    sold: FloorOverviewData[];
  };
}
export interface DaysOnMarketByLayoutData {
  layout: string;
  mean_days_on_market: number;
}
export interface DaysOnMarketData {
  mean_days_on_market: number;
  month: string;
  by_layout: DaysOnMarketByLayoutData[];
}
export interface DaysOnMarketResponse {
  days_on_the_market: DaysOnMarketData[];
}
export interface SizeComparisonData {
  layout: string;
  availability: string;
  market: {
    floor_area: number;
    exterior_area: number;
  };
  series: string;
  project_name: string;
}
export interface UnitMixData {
  layout: string;
  availability: string;
  value: number;
}
export interface Competitor {
  id: number;
  name: string;
}
export interface MarketDashboardOverview {
  dashboard_id: number;
  dashboard_name: string;
  template_id: number;
  template_type: MarketDashboardTemplateEnum;
  blocks: MarketDashboardBlockType[];
  competitors: Competitor[];
  area: string[];
  include_new_projects: boolean;
  included_construction_types: string[];
  included_unit_types: ProjectTypeEnum[];
  included_project_ids: number[];
  remaining_project_ids: number[];
}
export interface MarketDashboardTemplate {
  name: string;
  id: number;
  blocks_order: MarketDashboardBlockType[]; // always an array of 5 elements
  type: MarketDashboardTemplateEnum;
}

export interface UpdateProfile {
  price_per_sm_calculation?: PricePerSmCalcType;
  reserved_as_sold?: boolean;
  VAT_included?: boolean;
  reports_hierarchy?: string;
  language?: LanguageEnum;
  price_rounding?: string;
}

export interface SubscribedEmails {
  email_notification_list: string[];
  id: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DailyNewsTemplate extends DailyNewsData {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DailyNewsGpsTemplate extends GpsDailyNewsData {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DailyNewsPartialTemplate extends DailyNewsPartialData {}
