import { FC } from 'react';
import { message } from 'antd';
import * as XLSX from 'xlsx';
import { ExportToCsv, Options } from 'export-to-csv';
import { useTranslation } from 'react-i18next';
import { useStoreState, useRegionFormatting, useFlatLayout } from '../../hooks';
import { getFlats, getFlatsFromS3 } from '../../api';
import { logError } from '../../utils/utils';
import { Availability } from '../../store/types';
import { ExportDropdown } from '../ExportDropdown/ExportDropdown';
import { round } from '../../utils/formatters';
import { Flat } from '../../api/mockData/flatsData';

const options: Options = {
  filename: `${new Date()
    .toLocaleDateString('en-GB')
    .split('/')
    .reverse()
    .join('')}-market_units`,
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true,
};

export const csvExporter = new ExportToCsv(options);

export const MarketExport: FC = () => {
  const { calculateVatPrice, getVATText } = useRegionFormatting();
  const withVAT = useStoreState((state) => state.user.profile?.VAT_included);
  const marketEnabled = useStoreState(
    (state) => state.user.profile?.market_csv_enabled,
  );
  const { getLayout } = useFlatLayout();
  const { t } = useTranslation();
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const { columns } = useStoreState((state) => state.market);
  const attributesToIgnore = [
    'price',
    'price_per_sm',
    'original_price',
    'parking_outdoor_price',
    'parking_indoor_price',
    'layout',
    'payments',
    'balcony_area',
    'terrace_area',
    'garden_area',
    'city',
  ];

  const convertTmpCity = (city: string): string => {
    const [firstPart] = city.split('_');
    return firstPart.charAt(0).toUpperCase() + firstPart.slice(1).toLowerCase();
  };

  const handleButtonClick = async (
    format: string,
    filters: { available: boolean; reserved: boolean; sold: boolean },
  ): Promise<void> => {
    if (paramsPM) {
      try {
        message.loading(t('market.export.preparing_data'), 0);
        const { data } = await getFlats(paramsPM.dashboardId, paramsPM.date);
        const { is_prepared, s3url } = data;
        const { data: flats } = await getFlatsFromS3(s3url);

        if (is_prepared && (flats || []).length > 0) {
          const includedColumns = columns.filter((column) => column.included); // Filter only included columns

          const exportData = flats
            .filter((flat) => {
              // Filtering based on availability status
              if (
                filters.available &&
                flat.availability === Availability.AVAILABLE
              ) {
                return true;
              }
              if (
                filters.reserved &&
                flat.availability === Availability.RESERVED
              ) {
                return true;
              }
              if (filters.sold && flat.availability === Availability.SOLD) {
                return true;
              }
              return false;
            })
            .map((flat) => {
              const exportRow: any = {};

              // Only include the columns that are marked as included in the export
              includedColumns.forEach(({ column_name }) => {
                const key = column_name as keyof Flat;

                // Only skip mapping if the key exists in attributesToIgnore
                if (attributesToIgnore.includes(key)) {
                  return;
                }

                // Map each included column to the respective flat field, defaulting to an empty string
                exportRow[column_name] =
                  flat[key] !== undefined ? flat[key] : '';
              });

              // Handle 'balcony_area', 'terrace_area', and 'garden_area' by summing their values
              if (
                includedColumns.some(
                  (col) => col.column_name === 'balcony_area',
                )
              ) {
                exportRow.balcony_area = flat.balcony_area
                  ? flat.balcony_area.reduce((sum, val) => sum + Number(val), 0)
                  : 0;
              }

              if (
                includedColumns.some(
                  (col) => col.column_name === 'terrace_area',
                )
              ) {
                exportRow.terrace_area = flat.terrace_area
                  ? flat.terrace_area.reduce((sum, val) => sum + Number(val), 0)
                  : 0;
              }

              if (
                includedColumns.some((col) => col.column_name === 'garden_area')
              ) {
                exportRow.garden_area = flat.garden_area
                  ? flat.garden_area.reduce((sum, val) => sum + Number(val), 0)
                  : 0;
              }

              if (
                includedColumns.some((col) => col.column_name === 'payments') &&
                (flat.payment_construction ||
                  flat.payment_contract ||
                  flat.payment_occupancy)
              ) {
                exportRow.payments = `${
                  Number(flat.payment_contract) * 100 || 0
                }-${Number(flat.payment_construction) * 100 || 0}-${
                  Number(flat.payment_occupancy) * 100 || 0
                }`;
              }
              if (includedColumns.some((col) => col.column_name === 'layout')) {
                exportRow.layout = getLayout(flat.layout);
              }
              if (includedColumns.some((col) => col.column_name === 'city')) {
                exportRow.city = convertTmpCity(flat.city);
              }

              // Price-related fields based on inclusion and VAT
              if (withVAT) {
                if (
                  includedColumns.some((col) => col.column_name === 'price')
                ) {
                  exportRow.price_with_vat = flat.price
                    ? round(
                        calculateVatPrice(
                          flat.price,
                          flat.floor_area,
                          flat.exterior_area || 0,
                        ) || 0,
                        2,
                      )
                    : '';
                }

                if (
                  includedColumns.some(
                    (col) => col.column_name === 'original_price',
                  )
                ) {
                  exportRow.original_price_with_vat = flat.original_price
                    ? round(
                        calculateVatPrice(
                          flat.original_price,
                          flat.floor_area,
                          flat.exterior_area || 0,
                        ) || 0,
                        2,
                      )
                    : '';
                }

                if (
                  includedColumns.some(
                    (col) => col.column_name === 'price_per_sm',
                  )
                ) {
                  exportRow.price_per_sm_with_vat = flat.price_per_sm
                    ? round(
                        calculateVatPrice(
                          flat.price_per_sm,
                          flat.floor_area,
                          flat.exterior_area || 0,
                        ) || 0,
                        2,
                      )
                    : '';
                }

                if (
                  includedColumns.some(
                    (col) => col.column_name === 'parking_indoor_price',
                  )
                ) {
                  exportRow.parking_indoor_price_with_vat =
                    flat.parking_indoor_price
                      ? round(
                          calculateVatPrice(flat.parking_indoor_price, 1) || 0,
                          2,
                        )
                      : '';
                }

                if (
                  includedColumns.some(
                    (col) => col.column_name === 'parking_outdoor_price',
                  )
                ) {
                  exportRow.parking_outdoor_price_with_vat =
                    flat.parking_outdoor_price
                      ? round(
                          calculateVatPrice(flat.parking_outdoor_price, 1) || 0,
                          2,
                        )
                      : '';
                }
              } else {
                if (
                  includedColumns.some((col) => col.column_name === 'price')
                ) {
                  exportRow.price_without_vat = flat.price
                    ? round(
                        calculateVatPrice(
                          flat.price,
                          flat.floor_area,
                          flat.exterior_area || 0,
                        ) || 0,
                        2,
                      )
                    : '';
                }

                if (
                  includedColumns.some(
                    (col) => col.column_name === 'original_price',
                  )
                ) {
                  exportRow.original_price_without_vat = flat.original_price
                    ? round(
                        calculateVatPrice(
                          flat.original_price,
                          flat.floor_area,
                          flat.exterior_area || 0,
                        ) || 0,
                        2,
                      )
                    : '';
                }

                if (
                  includedColumns.some(
                    (col) => col.column_name === 'price_per_sm',
                  )
                ) {
                  exportRow.price_per_sm_without_vat = flat.price_per_sm
                    ? round(
                        calculateVatPrice(
                          flat.price_per_sm,
                          flat.floor_area,
                          flat.exterior_area || 0,
                        ) || 0,
                        2,
                      )
                    : '';
                }

                if (
                  includedColumns.some(
                    (col) => col.column_name === 'parking_indoor_price',
                  )
                ) {
                  exportRow.parking_indoor_price_without_vat =
                    flat.parking_indoor_price
                      ? round(
                          calculateVatPrice(flat.parking_indoor_price, 1) || 0,
                          2,
                        )
                      : '';
                }

                if (
                  includedColumns.some(
                    (col) => col.column_name === 'parking_outdoor_price',
                  )
                ) {
                  exportRow.parking_outdoor_price_without_vat =
                    flat.parking_outdoor_price
                      ? round(
                          calculateVatPrice(flat.parking_outdoor_price, 1) || 0,
                          2,
                        )
                      : '';
                }
              }

              return exportRow;
            });

          if (exportData.length === 0) {
            message.destroy();
            message.info(t('market.export.no_data'), 2);
            return;
          }
          if (format === 'csv') {
            csvExporter.options.filename = `${options.filename}${getVATText()}`;
            csvExporter.generateCsv(exportData);
          } else {
            const worksheet = XLSX.utils.json_to_sheet(exportData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, `${options.filename}${getVATText()}.xlsx`);
          }
          message.destroy();
          message.success(t('market.export.data_prepared'), 2);
        } else {
          logError('Data is not prepared for the export yet.');
        }
      } catch (error) {
        logError(error);
        message.error(t('error.generic'), 2);
      }
    }
  };

  return (
    <ExportDropdown
      isDisabled={!marketEnabled}
      type='marketData'
      onDownload={handleButtonClick}
    />
  );
};
