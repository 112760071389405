import { FC } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import {
  useFlatLayout,
  useRegionFormatting,
  useStoreState,
} from '../../../../hooks';
import {
  AVAILABLE,
  SOLD,
  COMPETITOR_COLORS,
} from '../../../../styles/constants';
import {
  shouldUseShorten,
  translateBlockChartValue,
} from '../../../../utils/utils';
import { getColumnOptions } from '../../../../utils/defaultChartConfig';
import { useMarketDetails } from '../../../../hooks/useMarketDetails';

export interface Data {
  value: number;
  pricePerSm: number;
  price: number;
  layout: string;
  type: string;
  availability: string;
}

interface Props {
  data: Data[];
  isPrice: boolean;
}
export const PriceComparisonChart: FC<Props> = ({ data, isPrice }) => {
  const { isRentals } = useMarketDetails();
  const { t } = useTranslation();
  const { getLayout } = useFlatLayout();
  const { formatCurrency } = useRegionFormatting();
  const { displayValues } = useStoreState((state) => state.filters);

  const translateValue = (value: string): string =>
    translateBlockChartValue(value, t, isRentals);

  const chartData: Data[] = data.map(
    ({ layout, availability, price, pricePerSm }) => ({
      value: isPrice ? price : pricePerSm,
      layout,
      availability,
      type: availability,
      price,
      pricePerSm,
    }),
  );

  const config: ColumnConfig = getColumnOptions({
    data: chartData,
    isGroup: true,
    xField: 'layout',
    yField: 'value',
    seriesField: 'type',
    color: [AVAILABLE, SOLD, ...COMPETITOR_COLORS],
    label: displayValues
      ? {
          formatter: ({ value }) => {
            if (value === 0) return '';
            const { shortenThousands, shortenMillions } =
              shouldUseShorten(value);
            return (
              formatCurrency(
                Number(value),
                undefined,
                shortenMillions,
                shortenThousands,
              ) ?? ''
            );
          },
          position: 'top',
          rotate: -Math.PI / 2,
          offsetX: 15,
          offsetY: 6,
        }
      : undefined,
    legend: {
      itemName: {
        formatter: (value) => translateValue(value),
      },
    },
    yAxis: {
      max:
        Math.max(
          ...data.map((d) =>
            isPrice ? d.price : d.pricePerSm,
          ),
        ) * 1.2,
      label: {
        formatter: (value) => formatCurrency(Number(value), !isPrice),
      },
    },
    xAxis: {
      label: {
        formatter: (value) => getLayout(value),
      },
    },
    tooltip: {
      formatter: (datum) => {
        const value = datum as Data;
        return {
          name: translateValue(value.type),
          value: formatCurrency(value.value, !isPrice) ?? '',
        };
      },
      title: (title) => getLayout(title),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${item?.color}"></div>
              <div class="bmt-label">
                ${item?.name}:
              </div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
  });
  return data.length > 0 ? (
    <Column {...config} />
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
