/* eslint-disable @typescript-eslint/no-shadow */
import { FC, useEffect, useState } from 'react';
import { LineConfig } from '@ant-design/plots';
import Segmented from 'antd/lib/segmented';
import { useTranslation } from 'react-i18next';
import { Datum } from '@antv/g2plot';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { AVAILABLE, BLUE_1, BLUE_2, BLUE_3, BLUE_4, BLUE_5, GREY_1, GREY_2, GREY_3, SOLD } from '../../../styles/constants';
import { useFlatLayout, useRegionFormatting, useStoreActions, useStoreState } from '../../../hooks';
import { translateBlockChartValue } from '../../../utils/utils';
import { PriceHistoryProjectData } from '../../../api/mockData/projectData';
import { PaginatedChartCard } from '../../PaginatedChartCard';
import { getLineOptions } from '../../../utils/defaultChartConfig';

enum FilterEnum {
  OVERALL,
  LAYOUTS,
}

enum AvailabilityFilterEnum {
  SOLD = 'sold',
  AVAILABLE = 'available',
  ALL = 'all'
}

enum PriceType {
  PRICE = 'flat_prices_price',
  PRICE_PER_SM = 'avg_price'
}

const getChart = (
  availability: AvailabilityFilterEnum,
  isOverall: boolean,
): 'Line' | 'Area' => {
  if (availability === AvailabilityFilterEnum.ALL || !isOverall) {
    return 'Line';
  }
  return 'Area';
};

export const PriceHistory: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { formatCurrency, areaUnit } = useRegionFormatting();
  const { getLayout } = useFlatLayout();
  const { fetchProjectPriceHistory } = useStoreActions((action) => action.project);
  const language = useStoreState((state) => state.user.profile?.language);
  const profile = useStoreState((state) => state.user.profile);
  const [availability, setAvailability] = useState<AvailabilityFilterEnum>(AvailabilityFilterEnum.AVAILABLE);
  const [filterValue, setFilterValue] = useState<FilterEnum>(FilterEnum.OVERALL);
  const [priceType, setPriceType] = useState<PriceType>(PriceType.PRICE_PER_SM);
  const isPricePerSm = priceType === PriceType.PRICE_PER_SM;
  const isBoth = availability === AvailabilityFilterEnum.ALL;
  const { displayValues } = useStoreState((state) => state.filters);
  const isOverall = filterValue === FilterEnum.OVERALL;
  const [data, setData] = useState<PriceHistoryProjectData[]>([]);

  useEffect(() => {
    if (profile && projectId) {
      const { reserved_as_sold, VAT_included, price_per_sm_calculation } = profile;
      fetchProjectPriceHistory({
        project_id: Number(projectId),
        reserved_as_sold,
        with_vat: VAT_included,
        price_per_sm_calculation,
      }).then((res: PriceHistoryProjectData[]) => {
        const sortedData = res.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        setData(sortedData);
      });
    }
  }, [fetchProjectPriceHistory, projectId]);

  const getColor = (): string[] => {
    if (isOverall) {
      if (availability === AvailabilityFilterEnum.SOLD) {
        return [SOLD];
      }
      if (availability === AvailabilityFilterEnum.AVAILABLE) {
        return [AVAILABLE];
      }
      if (data[0].availability === 'available') {
        return [AVAILABLE, SOLD, BLUE_3, BLUE_2, BLUE_5];
      }
      return [SOLD, AVAILABLE, BLUE_3, BLUE_2, BLUE_5];
    }
    return [BLUE_5, BLUE_4, BLUE_3, BLUE_2, GREY_1, GREY_2, GREY_3, BLUE_1];
  };

  const labelFormatter = (() => {
    let index = 0;
    return ({ avg_price, avg_price_per_sm }: Datum) => {
      const label = index % 4 === 0 ? formatCurrency(Number(isPricePerSm ? avg_price_per_sm : avg_price), undefined, undefined, priceType !== PriceType.PRICE_PER_SM) ?? '' : '';
      index++;
      return label;
    };
  })();

  const translateValue = (value: string): string =>
    isOverall ? translateBlockChartValue(value, t) : getLayout(value);

  const filteredData = isOverall ? data.filter((item) => item.layout === 'all_layouts') : data.filter((item) => item.layout !== 'all_layouts');

  const config = getLineOptions({
    // data: isBoth
    //   ? paginatedOverall[page]?.sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf())
    //   : paginatedAvailability[page]?.sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf()),
    color: getColor(),
    xField: 'date',
    yField: isPricePerSm ? 'avg_price_per_sm' : 'avg_price',
    seriesField: isOverall ? 'availability' : 'layout',
    label: displayValues ? {
      formatter: labelFormatter,
      style: {
        color: '#000',
      },
      position: 'top',
      rotate: -Math.PI / 3,
      offsetX: 15,
    } : undefined,
    tooltip: {
      formatter: (datum) => {
        const value = datum as PriceHistoryProjectData;
        return {
          name: isOverall ? String(value.availability) : value.layout,
          value: formatCurrency(isPricePerSm ? value.avg_price_per_sm : value.avg_price) ?? '-',
        };
      },
      title: (title) =>
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item?.color
              }"></div>
              <div class="bmt-label">${translateValue(item?.name)}:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
    xAxis: {
      type: 'time',
      tickCount: 12,
      label: {
        formatter: (date: string) => dayjs(date).format('MMM YY'),
        autoRotate: true,
      },
    },
    yAxis: {
      label: {
        formatter: (value) => formatCurrency(Number(value)),
        offsetY: 10,
      },
      max: Math.max(...filteredData.map((item) => (isPricePerSm ? item.avg_price_per_sm : item.avg_price))) * 1.2,
    },
    legend: {
      itemName: {
        formatter: translateValue,
      },
      padding: [20, 0, 0, 0],
      position: 'bottom',
      marker: {
        symbol: 'circle',
        style: {
          r: 12,
        },
      },
    },
    smooth: true,
    lineStyle: { lineWidth: 3 },
    animation: {
    appear: {
      animation: 'path-in',
      duration: 3000,
    },
  },
});

  return (
    <PaginatedChartCard
      title={t('market.reports.price_history.title', 'Price History')}
      subtitle={t(
        'project.price_history.subtitle',
        'The history of the average price of the project.',
      )}
      zoomInHeader
      controls={
      <div className='segmented-controls-wrapper'>
        <Segmented
          value={availability}
          options={[
            {
              value: AvailabilityFilterEnum.AVAILABLE,
              label: t('enums.state.available'),
            },
            {
              value: AvailabilityFilterEnum.SOLD,
              label: t('enums.state.sold'),
            },
            // {
            //   value: AvailabilityFilterEnum.ALL,
            //   label: t('enums.state.both'),
            // },
          ]}
          onChange={(val) => setAvailability(val as AvailabilityFilterEnum)}
        />
        <Segmented
          value={filterValue}
          options={[
            {
              value: FilterEnum.OVERALL,
              label: t('dashboard.sale_progress.overall', 'Overall'),
            },
            {
              value: FilterEnum.LAYOUTS,
              label: t('dashboard.sale_progress.layouts', 'Layouts'),
            },
          ]}
          onChange={(val) => setFilterValue(val as FilterEnum)}
        />
        <Segmented
          value={priceType}
          options={[
            {
              value: PriceType.PRICE_PER_SM,
              label: t('common.price_per_unit', 'Price per {{unit}}', {
                unit: areaUnit,
              }),
            },
            {
              value: PriceType.PRICE,
              label: t('common.price', 'Price'),
            },
          ]}
          onChange={(val) => setPriceType(val as PriceType)}
        />
      </div>
      }
      data={isBoth ? filteredData : filteredData.filter((item) => item.availability === availability)}
      config={config}
      chart={getChart(availability, isOverall)}
    />
  );
};
