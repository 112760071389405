import { FC, useMemo, useState } from 'react';
import { Segmented } from 'antd';
import clsx from 'clsx';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { MarketProjectExtended, Mode } from '../../../store/types';
import { ProjectCard } from '../../ProjectCard';
import { useStoreActions, useStoreState } from '../../../hooks';

interface ProjectDetailProps {
  selectedProject: MarketProjectExtended;
  selectedProjectId: number | null;
  sameLocationProjects: MarketProjectExtended[];
  onClose: () => void;
}

export const ProjectDetail: FC<ProjectDetailProps> = ({
  selectedProject,
  sameLocationProjects,
  selectedProjectId,
  onClose,
}) => {
  const { t } = useTranslation();
  const [activeProject, setActiveProject] = useState(
    selectedProject.project_id,
  );
  const currentProject = useMemo(
    () => sameLocationProjects.find((p) => p.project_id === activeProject),
    [activeProject, sameLocationProjects],
  );
  const { simplifiedProjects } = useStoreState((state) => state.market);
  const isIncluded =
    simplifiedProjects.find((project) => project.project_id === selectedProjectId)
      ?.include ?? false;
  const handleInclude = useStoreActions(
    (actions) => actions.market.handleInclude,
  );
  const mode = useStoreState((state) => state.market.mode);
  const handleClick = (): void => {
    handleInclude([selectedProjectId ?? activeProject]);
  };

  return (
    <div className='market-project-detail'>
      {[Mode.CREATE, Mode.EDIT].includes(mode) && (
        <div className='flex items-center justify-start border-b border-bmlightgray pb-6 select-none'>
          <div
            className={clsx(
              'flex items-center border rounded-full cursor-pointer',
              !!isIncluded
                ? 'border-bmblue text-bmblue font-semibold'
                : 'border-bmdarkgray text-bmgray font-normal',
            )}
            onClick={handleClick}
          >
            <CheckCircleIcon
              className={clsx(
                'w-10 h-10 justify-self-start',
                !!isIncluded ? 'text-[#5A72B1]' : 'text-bmlightgray',
              )}
            />
            <span className='justify-self-center px-4 py-1'>
              {t('market.projects.table.include')}
            </span>
          </div>
        </div>
      )}
      {currentProject && sameLocationProjects.length > 1 ? (
        <>
          <Segmented
            value={activeProject}
            options={sameLocationProjects.map((project) => ({
              label: project.project_name,
              value: project.project_id,
            }))}
            onChange={(v) => setActiveProject(Number(v))}
            className='my-4'
          />
          <ProjectCard data={currentProject} onClose={onClose} />
        </>
      ) : (
        <ProjectCard data={selectedProject} onClose={onClose} />
      )}
    </div>
  );
};
