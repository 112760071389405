import { FC, MutableRefObject, useEffect, useState } from 'react';
import { Unit } from '../../../api/mockData/priceListData';
import { useStoreActions } from '../../../hooks';
import { UnitInfo } from './UnitInfo';

type Props = {
  image: string | null;
  data?: Unit[];
  svgContainerRef: MutableRefObject<HTMLDivElement | null>;
};

const defaultFill = '#5A72B1';
const defaultOpacity = '0.5';
const defaultFillOpacity = '1';

export const PriceListFloorImage: FC<Props> = ({ image, data, svgContainerRef }) => {
  const [imageData, setImageData] = useState<string | null>(image);
  const [hoveredPathId, setHoveredPathId] = useState<string | null>(null);
  const [selectedPathId, setSelectedPathId] = useState<string | null>(null);
  const [cursorPosition, setCursorPosition] = useState<{ x: number; y: number } | null>(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const { setSelectedUnitId } = useStoreActions((actions) => actions.priceList);

  const getColorByAvailability = (availability: string, selected: boolean): string => {
    switch (availability) {
      case 'reserved':
        return selected ? '#CEC024' : '#FFFB9D';
      case 'sold':
        return selected ? '#828282' : '#D9D9D9';
      case 'available':
        return selected ? '#5A72B1' : 'transparent';
      default:
        return defaultFill;
    }
  };

  useEffect(() => {
    if (imageData !== image) {
      setImageData(image);
    }
  }, [image, imageData]);

  useEffect(() => {
    if (!imageData || !svgContainerRef.current) return;

    const parser = new DOMParser();
    const svgDocument = parser.parseFromString(imageData, 'image/svg+xml');
    const svgElement = svgDocument.querySelector('svg');

    if (svgElement) {
      svgContainerRef.current.innerHTML = '';
      svgContainerRef.current.appendChild(svgElement);
      svgContainerRef.current.classList.add('svg-container');
      svgElement.classList.add('svg-element');
      svgElement.querySelector('title')?.remove();
      const titles = svgElement.querySelectorAll('title');
      titles.forEach((title) => title.remove());

      const paths = Array.from(svgElement.querySelectorAll('path'));
      const pathIds = paths.map((p) => p.id);

      if (pathIds.length > 0) {
        setSelectedPathId(pathIds[0]);
        setSelectedUnitId(pathIds[0]);
      }
    }
  }, [imageData, svgContainerRef, setSelectedUnitId]);

  useEffect(() => {
    if (!svgContainerRef.current) return;

    const svgRef = svgContainerRef.current.querySelector('svg');
    if (!svgRef) return;

    const paths = Array.from(svgRef.querySelectorAll('path'));

    // eslint-disable-next-line consistent-return
    paths.forEach((path) => {
      const { id } = path;
      const item = data?.find((d) => d.name === id);

      if (item) {
        const handleMouseEnter = (event: MouseEvent): void => {
          const containerRect = svgContainerRef.current!.getBoundingClientRect();
          setHoveredPathId(id);
          setCursorPosition({
            x: event.clientX - containerRect.left,
            y: event.clientY - containerRect.top,
          });
          setTooltipVisible(true);
        };

        const handleMouseMove = (event: MouseEvent): void => {
          const containerRect = svgContainerRef.current!.getBoundingClientRect();
          setCursorPosition({
            x: event.clientX - containerRect.left,
            y: event.clientY - containerRect.top,
          });
        };

        const handleMouseLeave = (): void => {
          setTooltipVisible(false);
        };

        const handleClick = (): void => {
          setSelectedPathId(id);
          setSelectedUnitId(id);
        };

        path.addEventListener('mouseenter', handleMouseEnter);
        path.addEventListener('mousemove', handleMouseMove);
        path.addEventListener('mouseleave', handleMouseLeave);
        path.addEventListener('click', handleClick);

        return () => {
          path.removeEventListener('mouseenter', handleMouseEnter);
          path.removeEventListener('mousemove', handleMouseMove);
          path.removeEventListener('mouseleave', handleMouseLeave);
          path.removeEventListener('click', handleClick);
        };
      }
    });
  }, [data, svgContainerRef]);

  useEffect(() => {
    if (!svgContainerRef.current) return;

    const svgRef = svgContainerRef.current.querySelector('svg');
    if (!svgRef) return;

    const paths = svgRef.querySelectorAll('path');
    paths.forEach((path) => {
      const { id } = path;
      const item = data?.find((d) => d.name === id);

      if (item) {
        const isSelected = selectedPathId === id;
        if (isSelected) {
          path.style.fill = getColorByAvailability(item.availability, isSelected);
          path.style.opacity = '0.6';
          path.style.fillOpacity = defaultFillOpacity;
          path.style.cursor = 'pointer';
        } else {
          path.style.fill = getColorByAvailability(item.availability, false);
          path.style.opacity = defaultOpacity;
          path.style.fillOpacity = defaultFillOpacity;
          path.style.cursor = 'pointer';
        }
      }
    });
  }, [selectedPathId, data]);

  const height = svgContainerRef.current ? svgContainerRef.current.getBoundingClientRect().height : null;
  const width = svgContainerRef.current ? svgContainerRef.current?.getBoundingClientRect().width : null;
  const isHeightOriented = !!(height && width && height > width);

  return (
    <div className='relative'>
      <div
        ref={svgContainerRef}
        className={`${isHeightOriented ? 'svg-container-height-or' : 'svg-container-width-or'} flex items-center justify-center h-auto`}
      />
      {hoveredPathId && cursorPosition && tooltipVisible && (
        <UnitInfo
          position={{
            x: cursorPosition.x - 120,
            y: cursorPosition.y - 170,
          }}
          unit={data?.find((d) => d.name === hoveredPathId)}
        />
      )}
    </div>
  );
};
