/** @jsxImportSource @emotion/react */
import { FC, useEffect, useRef, useState } from 'react';
import { Segmented, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../../../hooks';
import { useFilter } from '../useFilter';
import { Data, SupplyHistoryChart } from './SupplyHistoryChart';
import { styles } from '../styles';
import { ChartCard } from '../../../ChartCard';
import { statisticsApi } from '../../../../api/secondary';
import { getReportExportFileName } from '../../../../utils/utils';
import { CardPopover } from '../CardPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';

export enum FilterEnum {
  OVERALL,
  NEW,
}

const formatter = (dateStr: string): string => {
  const d = new Date(dateStr);
  return `${d.getFullYear()}-${(`0${d.getMonth() + 1}`).slice(-2)}-01`;
};

export const SupplyHistory: FC = () => {
  const [data, setData] = useState<Data[]>([]);
  const [isLoading, setLoading] = useState(true);
  const { paramsSM } = useStoreState((state) => state.filters);
  const reportId = useStoreState((state) => state.secondaryMarket.activeReportId);
  const { t } = useTranslation();
  const { filterValue, handleChange } = useFilter(FilterEnum.OVERALL);
  const pngRef = useRef(null);

  useEffect(() => {
    if (reportId && paramsSM?.date) {
      setLoading(true);
      const date = new Date(paramsSM.date);
      date.setDate(date.getDate() - 180);
      const startDate = date.toISOString().split('T')[0];
      const endDate = paramsSM.date;
      const payload = {
        reportId,
        startDate,
        endDate,
      };
      if (filterValue === FilterEnum.OVERALL) {
        statisticsApi.supplyHistory(payload)
          .then((response) => {
            setData([
              ...response.map((d) => ({
                newUnits: 0,
                ...d,
              })),
            ]);
          }).finally(() => setLoading(false));
      } else {
        statisticsApi.addedUnitsOverTime(payload)
          .then((response) => {
            const newData: Data[] = [];
            response.newUnits.forEach((res) => {
              const createdDate = formatter(res.createdDate);
              newData.push({
                createdDate,
                availableUnits: 0,
                newUnits: response.newUnits.reduce((sum, curr) => {
                  if (formatter(curr.createdDate) === createdDate) {
                    sum += curr.newUnits;
                  }
                  return sum;
                }, 0),
              });
            });
            setData(newData);
          }).finally(() => setLoading(false));
      }
    }
  }, [filterValue, reportId, paramsSM]);

  const fileName = getReportExportFileName(paramsSM?.name || 'report', t('market.reports.supply_history.title'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
    <ChartCard
      title={t('market.reports.supply_history.title', 'Supply History')}
      subtitle={t(
        'market.reports.supply_history.subtitle',
        'The history of the number of available units.',
      )}
      extra={!isLoading && <CardPopover showValues onDownloadPNG={() => downloadPNG(fileName, pngRef)} />}
      chart={
        // eslint-disable-next-line no-nested-ternary
        isLoading ? (
          <Spin css={styles.center} spinning />
        ) : (
          data.length > 0 ?
          <SupplyHistoryChart
            filter={filterValue as FilterEnum}
            data={data.sort((a, b) => a.createdDate.localeCompare(b.createdDate))}
          />
          : null
        )
      }
      controls={
        <Segmented
          value={filterValue}
          options={[
              {
                value: FilterEnum.OVERALL,
                label: t('dashboard.sale_progress.overall', 'Overall'),
              },
              {
                value: FilterEnum.NEW,
                label: t('dashboard.sale_progress.new', 'New'),
              },
            ]}
          onChange={handleChange}
        />
      }
    />
    </div>
  );
};
