import { Scatter, ScatterConfig } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ActionCreator } from 'easy-peasy';
import { FC, useMemo } from 'react';
import { PricedUnit } from '../../../api/mockData/dashboardData';
import { Availability } from '../../../store/types';
import { useRegionFormatting, useStoreState } from '../../../hooks';

const shapeMapping = {
  available: 'circle',
  sold: 'cross',
};

type ExtendedPricedUnit = PricedUnit & {
  projectId: number;
  projectName: string;
};

type PositioningScatterChartProps = {
  data: ExtendedPricedUnit[];
  priceFormat: string;
  projectColors: Record<number, string>;
  activeItems: string[];
  setActiveItems: ActionCreator<string[]>;
};

export const PositioningScatterChart: FC<PositioningScatterChartProps> = ({
  data,
  priceFormat,
  projectColors,
  activeItems,
  setActiveItems,
}) => {
  const { t } = useTranslation();
  const { formatCurrency, formatAreaUnits, applyRounding } =
    useRegionFormatting();

  // Memoized grouped legend items to ensure stability
  const groupedLegendItems = useMemo(
    () =>
      data.reduce((acc, item) => {
        const key = item.projectId;
        if (!acc[key]) {
          acc[key] = {
            projectId: item.projectId,
            available: null,
            soldAndReserved: null,
          };
        }

        if (item.availability === Availability.AVAILABLE) {
          acc[key].available = {
            id: `${item.projectId}-available`,
            projectName: item.projectName,
            label: 'available',
            color: projectColors[item.projectId],
            shape: 'circle',
          };
        }

        if (
          item.availability === Availability.SOLD ||
          item.availability === Availability.RESERVED
        ) {
          const label = 'sold_reserved';
          if (!acc[key].soldAndReserved) {
            acc[key].soldAndReserved = {
              id: `${item.projectId}-sold`,
              projectName: item.projectName,
              label,
              color: projectColors[item.projectId],
              shape: 'cross',
            };
          }
        }

        return acc;
      }, {} as Record<number, { projectId: number; available: any; soldAndReserved: any }>),
    [data],
  );

  const legendItemsByProject = useMemo(
    () => Object.values(groupedLegendItems),
    [groupedLegendItems],
  );

  const filteredData = useMemo(
    () =>
      data.filter((item) => {
        const soldOrReserved = 'sold';
        const availability =
          item.availability === Availability.AVAILABLE
            ? item.availability
            : soldOrReserved;

        if (item.availability === Availability.RESERVED) {
          return false;
        }

        return activeItems.includes(`${item.projectId}-${availability}`);
      }),
    [data, activeItems],
  );

  const toggleLegendItem = (id: string): void => {
    setActiveItems(
      activeItems.includes(id)
        ? activeItems.filter((item) => item !== id)
        : [...activeItems, id],
    );
  };

  const config: ScatterConfig = {
    data: filteredData,
    xField: 'floor_area',
    yField: priceFormat,
    colorField: 'projectId',
    shapeField: 'availability',
    sizeField: 'price',
    size: 6,
    color: ({ projectId }) => projectColors[projectId],
    shape: (datum) => {
      const { availability } = datum as PricedUnit;
      return shapeMapping[availability as keyof typeof shapeMapping] || 'cross';
    },
    xAxis: {
      grid: null,
      label: {
        formatter: (text: string) => formatAreaUnits(Number(text)),
      },
    },
    yAxis: {
      grid: null,
      nice: true,
      label: {
        formatter: (text: string) => formatCurrency(Number(text)),
      },
    },
    tooltip: {
      showTitle: false,
      customContent: (_, items) => {
        if (!items || items.length === 0) return null;
        const datum = items[0]?.data;

        return (
          <div className='py-4 m-4 space-y-4 bg-white'>
            <div className='font-bold'>{datum.projectName}</div>
            <div>{datum.name}</div>
            <div className='font-bold'>{formatAreaUnits(datum.floor_area)}</div>
            <div className='font-bold'>
              {formatCurrency(
                applyRounding(
                  priceFormat === 'price' ? datum.price : datum.price_per_sm,
                ),
              )}
            </div>
            <div>{t(`enums.state.${datum.availability}`)}</div>
          </div>
        );
      },
    },
    legend: false,
    scrollbar: false,
    appendPadding: 20,
  };

  return (
    <div>
      <Scatter {...config} />
      <div
        className='grid gap-4 m-auto mb-4 w-fit'
        style={{
          gridTemplateColumns: `repeat(${legendItemsByProject.length}, minmax(0, 1fr))`,
        }}
      >
        {/* Show Available separately */}
        {legendItemsByProject.map((project) => {
          if (project.available) {
            const isActive = activeItems.includes(project.available.id);
            return (
              <div
                key={`available-${project.projectId}`}
                onClick={() => toggleLegendItem(project.available.id)}
                className={`flex items-center justify-start cursor-pointer transition-opacity ${
                  isActive ? 'opacity-100' : 'opacity-50'
                }`}
              >
                <div
                  className='m-[4px] w-[12px] h-[12px] rounded-full mr-2 flex-shrink-0'
                  style={{ backgroundColor: project.available.color }}
                />
                <span className='text-[1rem] text-[#828282]'>
                  {`${project.available.projectName} - ${t(
                    `charts.state.${project.available.label}`,
                  )}`}
                </span>
              </div>
            );
          }
          return <div key={`available-${project.projectId}`} />;
        })}

        {legendItemsByProject.map((project) => {
          if (project.soldAndReserved) {
            const isActive = activeItems.includes(project.soldAndReserved.id);
            return (
              <div
                key={`sold-reserved-${project.projectId}`}
                onClick={() => toggleLegendItem(project.soldAndReserved.id)}
                className={`flex items-center justify-start cursor-pointer transition-opacity ${
                  isActive ? 'opacity-100' : 'opacity-50'
                }`}
              >
                <XMarkIcon
                  className='w-8 h-8 flex-shrink-0'
                  style={{ color: project.soldAndReserved.color }}
                />
                <span className='text-[1rem] text-[#828282]'>
                  {`${project.soldAndReserved.projectName} - ${t(
                    `charts.state.${project.soldAndReserved.label}`,
                  )}`}
                </span>
              </div>
            );
          }
          return <div key={`sold-reserved-${project.projectId}`} />;
        })}
      </div>
    </div>
  );
};
