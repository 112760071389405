import { FC, ReactNode } from 'react';
import { Column, ColumnConfig, AreaConfig, Area } from '@ant-design/plots';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { AVAILABLE } from '../../../../styles/constants';
import { getLineOptions } from '../../../../utils/defaultChartConfig';
import { FilterEnum } from '.';
import { useStoreState } from '../../../../hooks';

export interface Data {
  createdDate: string;
  availableUnits: number;
  newUnits: number;
}
interface Props {
  filter: FilterEnum;
  data: Data[];
}

const getChart = (
  filter: FilterEnum,
  config: AreaConfig | ColumnConfig,
): ReactNode => {
  if (filter === FilterEnum.NEW) {
    return <Column {...(config as ColumnConfig)} />;
  }
  return <Area {...(config as AreaConfig)} />;
};

export const SupplyHistoryChart: FC<Props> = ({ data, filter }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { displayValues } = useStoreState((state) => state.filters);

  const overallLabel = displayValues
    ? {
        style: {
          color: '#000',
        },
        offsetX: 0.5,
        rotate: -Math.PI / 3,
      }
    : undefined;

  const newLabel = displayValues
    ? {
        style: {
          color: '#000',
        },
        position: 'top' as const,
      }
    : undefined;

  const config: AreaConfig = getLineOptions({
    smooth: true,
    data,
    color: AVAILABLE,
    xField: 'createdDate',
    yField: filter === FilterEnum.OVERALL ? 'availableUnits' : 'newUnits',
    label: filter === FilterEnum.OVERALL ? overallLabel : newLabel,
    tooltip: {
      formatter: (datum) => {
        const value = datum as Data;
        return {
          name: value.createdDate,
          value:
            filter === FilterEnum.NEW
              ? value.newUnits
              : value.availableUnits ?? '-',
        };
      },
      title: (title) =>
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          year: 'numeric',
        }),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item?.color
              }"></div>
              <div class="bmt-label">${t('enums.state.available')}:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
    marginRatio: 0.3,
    columnStyle: {
      radius: [7, 7, 0, 0],
      color: AVAILABLE,
    },
    xAxis: {
      type: 'time',
      label: {
        formatter: (value) => {
          const dateValue = new Date(value);
          return dateValue.toLocaleDateString(language, {
            month: 'long',
            year: 'numeric',
          });
        },
        autoHide: false,
        autoRotate: true,
      },
      tickMethod: 'time',
    },
    yAxis: {
      max:
        filter !== FilterEnum.OVERALL
          ? Math.max(...data.map((d) => d.newUnits)) * 1.1
          : undefined,
      label: {
        formatter: (value) => value,
      },
    },
    legend: {
      itemName: {
        formatter: () => t('enums.state.available'),
      },
    },
    stroke: 'black',
    fill: 'red',
  });

  return data.length > 0 ? (
    <>{getChart(filter, config)}</>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
