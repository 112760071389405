import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Card, Spin } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';
import { useTranslation } from 'react-i18next';
import { useBreakpoints, useStoreState } from '../../../hooks';
import { getPriceFloorViewImage } from '../../../api';
import { PriceListFloorImage } from './PriceListFloorImage';
import { Segmented } from '../../Segmented';
import { ReactComponent as ModernBuilding } from '../../../assets/icons/modern-building.svg';
import { ReactComponent as StackIcon } from '../../priceListDetail/DetailPreviewCard/icons/stackIcon.svg';
import { Pagination } from '../../Pagination';

export const PriceListFloorPlan: FC = () => {
  const [imageData, setImageData] = useState<string | null>(null);
  const { pricingData } = useStoreState((state) => state.priceList);
  const developer_id = useStoreState((state) => state.user.profile?.developer_id);
  const project_id = useStoreState((state) => state.filters.projectId);
  const data = useMemo(() => pricingData?.units || [], [pricingData]);
  const buildings = [...new Set(data.map((d) => d.building_name))];
  const [isFloorPlanAvailable, setIsFloorPlanAvailable] = useState<boolean>(true);
  const { t } = useTranslation();
  const { isLargeScreen } = useBreakpoints();

  const [selectedBuilding, setSelectedBuilding] = useState<SegmentedValue>(buildings[0]);

  const filteredData = useMemo(
    () => data.filter((item) => item.building_name === selectedBuilding),
    [data, selectedBuilding],
  );

  const [floors, setFloors] = useState<number[]>([]);
  const [selectedFloor, setSelectedFloor] = useState<number | null>(1);

  const svgContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const newFloors = [...new Set(filteredData.map((d) => d.floor))];
    setFloors(newFloors);

    const newSelectedFloor = newFloors.includes(selectedFloor ?? 0)
      ? selectedFloor
      : newFloors[newFloors.length - 1] || null;

    setSelectedFloor(newSelectedFloor);

    setImageData(null);
  }, [filteredData]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        developer_id &&
        project_id &&
        selectedBuilding &&
        selectedFloor !== null &&
        floors.includes(selectedFloor)
      ) {
        setImageData(null);

        getPriceFloorViewImage({
          developer_id,
          project_id,
          building_name: selectedBuilding as string,
          floor: selectedFloor.toString(),
        })
          .then((res: { data: string; status: number }) => {
            setImageData(res.data);
          })
          .catch((err) => {
            setIsFloorPlanAvailable(false);
            console.error('Error fetching floor data:', err);
          });
      }
    }, 200);

    return () => clearTimeout(timeout);
  }, [developer_id, project_id, selectedBuilding, selectedFloor, floors]);

  return (
    isFloorPlanAvailable ? (
    <Card className='price-list-floor'>
      <div className='absolute flex space-x-4 top-5 items-center justify-center w-full'>
        <div className='flex flex-col items-center justify-center w-full space-y-5'>
        {floors && selectedFloor && (
          <Pagination
            prefixIcon={<StackIcon className='w-8 h-8' />}
            title={t('enums.house_areas.floor')}
            showSizeChanger={false}
            size={isLargeScreen ? 'small' : 'default'}
            total={floors.length * 10}
            current={selectedFloor || 1}
            onChange={(value) => setSelectedFloor(value)}
          />
        )}
        <Segmented
          prefixIcon={<ModernBuilding className='w-8 h-8' />}
          title={t('header.building')}
          value={selectedBuilding}
          size={isLargeScreen ? 'small' : 'large'}
          disabled={buildings.length === 0}
          onChange={(value) => setSelectedBuilding(value)}
          options={buildings.map((b) => ({ value: b, label: b }))}
        />
        </div>
      </div>
      {!pricingData || !imageData || !selectedFloor || !selectedBuilding ? (
        <div className='flex w-full h-full items-center justify-center'>
          <Spin />
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center w-full'>
          <PriceListFloorImage
            svgContainerRef={svgContainerRef}
            image={imageData}
            data={data}
          />
        </div>
      )}
    </Card>
    ) : (
      <Card>
        <div className='flex h-full items-center justify-center'>
          <h1 className='text-2xl text-center'>Floor plan is currently unavailable for this project</h1>
        </div>
      </Card>
    )
  );
};
