import { FC, useEffect, useRef, useState } from 'react';
import { Segmented as AntdSegemnted, SegmentedProps } from 'antd';

type Props = {
  prefixIcon?: React.ReactNode;
  title?: string;
} & Omit<SegmentedProps, 'ref'> & React.RefAttributes<HTMLDivElement>;

export const Segmented: FC<Props> = ({ prefixIcon, title, ...segmentedProps }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState<number | null>(null);

    useEffect(() => {
      if (ref.current) {
        setContainerHeight(ref.current.getBoundingClientRect().height);
      }
    }, []);

  return (
    <div
      className='space-x-4 flex items-center justify-center rounded-[20px] border-l border-t border-b border-bmdarkgray text-bmblue pl-6 select-none w-auto z-50 bg-white'
      style={{ height: containerHeight || 'auto' }}
    >
      {(prefixIcon || title) && (
        <div className='flex space-x-2 items-center justify-center z-20'>
          {prefixIcon && prefixIcon}
          {title && <span>{title}</span>}
        </div>
      )}
      <div>
        <AntdSegemnted
          ref={ref}
          className='cursor-pointer'
          {...segmentedProps}
        />
      </div>
    </div>
  );
};
