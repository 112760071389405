/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Segmented, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../../../hooks';
import { useFilter } from '../useFilter';
import { styles } from '../styles';
import { Data, UnitMixChart } from './UnitMixChart';
import { Availability } from '../../../../store/types';
import { ChartCard } from '../../../ChartCard';
import { statisticsApi } from '../../../../api/secondary';
import { getReportExportFileName } from '../../../../utils/utils';
import { CardPopover } from '../CardPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { useMarketDetails } from '../../../../hooks/useMarketDetails';

enum FilterEnum {
  AVAILABLE,
  SOLD,
  TOTAL,
}

export const UnitMix: FC = () => {
  const { isRentals } = useMarketDetails();
  const [data, setData] = useState<Data[]>([]);
  const [isLoading, setLoading] = useState(true);
  const reportId = useStoreState((state) => state.secondaryMarket.activeReportId);
  const { paramsSM } = useStoreState((state) => state.filters);
  const { t } = useTranslation();
  const { filterValue, handleChange } = useFilter(FilterEnum.AVAILABLE);
  const pngRef = useRef(null);
  const isSold = useStoreState((state) => state.user.profile?.reserved_as_sold);
  const soldSegmentText = t(isSold ? 'enums.state.sales_reservations' : 'enums.state.sales');

  // eslint-disable-next-line no-nested-ternary
  const chart = useMemo(() => isLoading ? (
      <Spin css={styles.center} spinning />
    ) : (
      data.length > 0 ? (
      <UnitMixChart data={data} />
      ) : null
    ), [data, isLoading]);

  const controls = useMemo(
    () => (
      <Segmented
        value={filterValue}
        options={[
          {
            value: FilterEnum.AVAILABLE,
            label: t('enums.state.available', 'Available'),
          },
          {
            value: FilterEnum.SOLD,
            label: isRentals ? t('enums.state.leased') : soldSegmentText,
          },
          {
            value: FilterEnum.TOTAL,
            label: t('project.unit_mix.total', 'Total'),
          },
        ]}
        onChange={handleChange}
      />
    ),
    [filterValue, handleChange, isRentals, t],
  );

  useEffect(() => {
    if (reportId && paramsSM?.date) {
      setLoading(true);
      const payload = {
        date: paramsSM.date,
        reportId,
      };
      switch (filterValue) {
        case FilterEnum.AVAILABLE:
          statisticsApi.availableUnitsUnitMix(payload)
            .then((response) => {
              setData([
                ...(response.availableUnitsLayout180 || response.availableUnitsLayout).map((d) => ({
                  layout: `layout_${d.countOfRegularRooms}`,
                  value: d.units,
                  availability: Availability.AVAILABLE,
                })),
              ]);
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        case FilterEnum.SOLD:
          statisticsApi.disappearedUnitsUnitMix(payload)
            .then((response) => {
              setData([
                ...(
                  response.disappearedUnitsLayout180 ||
                  response.disappearedUnitsLayout
                  ).map((d) => ({
                  layout: `layout_${d.countOfRegularRooms}`,
                  value: d.units,
                  availability: Availability.SOLD,
                })),
              ]);
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        default:
          Promise.all([
            statisticsApi.availableUnitsUnitMix(payload),
            statisticsApi.disappearedUnitsUnitMix(payload),
          ]).then((response) => {
            const [available, disappeared] = response;
            setData([
              ...(
                available.availableUnitsLayout180 ||
                available.availableUnitsLayout ||
                []
                ).map((d) => ({
                layout: `layout_${d.countOfRegularRooms}`,
                value: d.units,
                availability: Availability.AVAILABLE,
              })),
              ...(
                disappeared.disappearedUnitsLayout180 ||
                disappeared.disappearedUnitsLayout ||
                []
                ).map((d) => ({
                layout: `layout_${d.countOfRegularRooms}`,
                value: d.units,
                availability: Availability.SOLD,
              })),
            ]);
          }).finally(() => {
              setLoading(false);
            });
          break;
      }
    }
  }, [filterValue, reportId, paramsSM]);

  const fileName = getReportExportFileName(paramsSM?.name || 'report', t('market.reports.unit_mix.title'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
    <ChartCard
      extra={!isLoading && <CardPopover showValues onDownloadPNG={() => downloadPNG(fileName, pngRef)} />}
      title={t('market.reports.unit_mix.title', 'Unit Mix')}
      subtitle={t(
        'market.reports.unit_mix.subtitle',
        'The distribution of units by layouts in the selected projects.',
      )}
      controls={controls}
      chart={chart}
    />
    </div>
  );
};
