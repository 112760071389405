import { FC } from 'react';
import { message } from 'antd';
import * as XLSX from 'xlsx';
import { ExportToCsv, Options } from 'export-to-csv';
import { useTranslation } from 'react-i18next';
import { useStoreState, useRegionFormatting, useFlatLayout } from '../../hooks';
import { logError } from '../../utils/utils';
import { Availability } from '../../store/types';
import { ExportDropdown } from '../ExportDropdown/ExportDropdown';
import { round } from '../../utils/formatters';
import { PriceList } from '../../api/types';

const options: Options = {
  filename: `${new Date()
    .toLocaleDateString('en-GB')
    .split('/')
    .reverse()
    .join('')}-market_units`,
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true,
};

export const csvExporter = new ExportToCsv(options);

type MarketExportProps = {
  data?: PriceList[];
};

export const ProjectExport: FC<MarketExportProps> = ({ data }) => {
  const { getVATText } = useRegionFormatting();
  const withVAT = useStoreState((state) => state.user.profile?.VAT_included);
  const marketEnabled = useStoreState(
    (state) => state.user.profile?.market_csv_enabled,
  );
  const { getLayout } = useFlatLayout();
  const { t } = useTranslation();
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const { projectColumns: columns } = useStoreState((state) => state.market);
  const attributesToIgnore = [
    'price',
    'price_per_sm',
    'original_price',
    'original_price_per_sm',
    'parking_outdoor_price',
    'parking_indoor_price',
    'layout',
    'payments',
    'balcony_area',
    'terrace_area',
    'garden_area',
    'city',
  ];

  const handleButtonClick = async (
    format: string,
    filters: { available: boolean; reserved: boolean; sold: boolean },
  ): Promise<void> => {
    if (!data) {
      return;
    }
    if (paramsPM) {
      try {
        message.loading(t('market.export.preparing_data'), 0);
        const includedColumns = columns.filter((column) => column.included); // Filter only included columns

        const exportData = data
          .filter((flat) => {
            // Filtering based on availability status
            if (
              filters.available &&
              flat.availability === Availability.AVAILABLE
            ) {
              return true;
            }
            if (
              filters.reserved &&
              flat.availability === Availability.RESERVED
            ) {
              return true;
            }
            if (filters.sold && flat.availability === Availability.SOLD) {
              return true;
            }
            return false;
          })
          .map((flat) => {
            const exportRow: any = {};

            // Only include the columns that are marked as included in the export
            includedColumns.forEach(({ column_name }) => {
              const key = column_name as keyof PriceList;

              // Only skip mapping if the key exists in attributesToIgnore
              if (attributesToIgnore.includes(key)) {
                return;
              }

              // Map each included column to the respective flat field, defaulting to an empty string
              exportRow[column_name] = flat[key] !== undefined ? flat[key] : '';
            });

            if (includedColumns.some((col) => col.column_name === 'layout')) {
              exportRow.layout = getLayout(flat.layout);
            }

            // Price-related fields based on inclusion and VAT
            if (withVAT) {
              if (includedColumns.some((col) => col.column_name === 'price')) {
                exportRow.price_with_vat = flat.price
                  ? round(flat.price, 2)
                  : '';
              }

              if (
                includedColumns.some(
                  (col) => col.column_name === 'original_price',
                )
              ) {
                exportRow.original_price_with_vat = flat.original_price
                  ? round(flat.original_price, 2)
                  : '';
              }

              if (
                includedColumns.some(
                  (col) => col.column_name === 'original_price_per_sm',
                )
              ) {
                exportRow.original_price_per_sm_with_vat = flat.original_price_per_sm
                  ? round(flat.original_price_per_sm, 2)
                  : '';
              }

              if (
                includedColumns.some(
                  (col) => col.column_name === 'price_per_sm',
                )
              ) {
                exportRow.price_per_sm_with_vat = flat.price_per_sm
                  ? round(flat.price_per_sm, 2)
                  : '';
              }
            } else {
              if (includedColumns.some((col) => col.column_name === 'price')) {
                exportRow.price_without_vat = flat.price
                  ? round(flat.price, 2)
                  : '';
              }

              if (
                includedColumns.some(
                  (col) => col.column_name === 'original_price',
                )
              ) {
                exportRow.original_price_without_vat = flat.original_price
                  ? round(flat.original_price, 2)
                  : '';
              }

              if (
                includedColumns.some(
                  (col) => col.column_name === 'original_price_per_sm',
                )
              ) {
                exportRow.original_price_per_sm_without_vat =
                  flat.original_price_per_sm
                    ? round(flat.original_price_per_sm, 2)
                    : '';
              }

              if (
                includedColumns.some(
                  (col) => col.column_name === 'price_per_sm',
                )
              ) {
                exportRow.price_per_sm_without_vat = flat.price_per_sm
                  ? round(flat.price_per_sm, 2)
                  : '';
              }
            }

            return exportRow;
          });

        if (exportData.length === 0) {
          message.destroy();
          message.info(t('market.export.no_data'), 2);
          return;
        }
        if (format === 'csv') {
          csvExporter.options.filename = `${options.filename}${getVATText()}`;
          csvExporter.generateCsv(exportData);
        } else {
          const worksheet = XLSX.utils.json_to_sheet(exportData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          XLSX.writeFile(workbook, `${options.filename}${getVATText()}.xlsx`);
        }
        message.destroy();
        message.success(t('market.export.data_prepared'), 2);
      } catch (error) {
        logError(error);
        message.error(t('error.generic'), 2);
      }
    }
  };

  return (
    <ExportDropdown
      isDisabled={!marketEnabled}
      type='marketData'
      onDownload={handleButtonClick}
    />
  );
};
