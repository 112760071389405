/** @jsxImportSource @emotion/react */
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { FC } from 'react';
import { Button, Popover, message } from 'antd';
import { ExportToCsv, Options } from 'export-to-csv';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useRegionFormatting, useFlatLayout, useStoreState } from '../../hooks';
import { useReservedData } from '../../hooks/useReservedData';
import { Availability } from '../../store/types';
import { logError } from '../../utils/utils';
import { round } from '../../utils/formatters';

const options: Options = {
  filename: `${new Date()
    .toLocaleDateString('en-GB')
    .split('/')
    .reverse()
    .join('')}-market_units`,
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true,
};

const styles = {
  download: css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '15px',
    fontSize: '14px',
    fontWeight: 700,
    padding: '0px 10px',
  }),
  downloadIcon: css({
    width: '20px',
    height: '20px',
    strokeWidth: '2px',
    marginRight: '5px',
    marginLeft: '5px',
  }),
};

export enum FilterEnum {
  AVAILABLE = 'Available',
  SOLD = 'Sold',
  TOTAL = 'Total',
}

export const csvExporter = new ExportToCsv(options);

type PDFProps = {
  onDownloadPDF: () => void
  isLoadingDownload: boolean
  marginRight?: boolean,
}

export const PDFExport: FC<PDFProps> = ({ onDownloadPDF, isLoadingDownload, marginRight = false }) => {
  const { t } = useTranslation();

  return (
    <Button
      css={styles.download}
      icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
      onClick={onDownloadPDF}
      disabled={isLoadingDownload}
      style={{ marginRight: marginRight ? '10px' : 0 }}
    >
      {isLoadingDownload ? `${t('download.processing_data', 'Preparing your data')}...` : 'PDF'}
    </Button>
  );
};

export const Export: FC = () => {
  const { getCurrency, getVATText } = useRegionFormatting();
  const withVAT = useStoreState((state) => state.user.profile?.VAT_included);
  const { getLayout } = useFlatLayout();
  const { t } = useTranslation();
  const project_data = useStoreState((state) => state.project.data);
  const data = useStoreState((state) => state.project.data?.price_list ?? []);
  const { mapReservedData } = useReservedData();

  const available = useStoreState((state) => state.filters.availability);

  const handleButtonClick = (exportFormat: string): void => {
    try {
      message.loading(t('market.export.preparing_data'), 0);
      const preparedData = mapReservedData(data);
      let filteredData;
      if (available === FilterEnum.AVAILABLE) {
        filteredData = preparedData.filter((flat) => flat.availability === Availability.AVAILABLE);
      } else {
        filteredData = preparedData.filter((flat) => flat.availability === Availability.SOLD);
      }
      const exportData = filteredData.map(
        ({
          id,
          availability,
          building,
          category,
          orientation,
          DOM,
          first_seen,
          exterior_area,
          floor,
          floor_area,
          layout,
          price,
          price_per_sm,
          total_area,
          url,
          original_price,
          original_price_per_sm,
        }) => ({
          project: project_data?.project_name,
          developer: project_data?.developer,
          address: project_data?.address,
          id,
          building: building ?? '',
          category,
          availability: t(`enums.state.${availability.toLowerCase()}`),
          exterior_area: exterior_area ?? 0,
          floor: floor ?? '',
          floor_area: !project_data?.using_total_area ? (floor_area ?? '') : '',
          layout: getLayout(layout),
          ...{
            ...withVAT ? {
              price_with_vat: price ? round(price, 2) : '',
              original_price_with_vat: original_price ? round(original_price, 2) : '',
              original_price_per_sm_with_vat: original_price_per_sm ? round(original_price_per_sm, 2) : '',
              price_change: price && original_price && price / original_price !== 1 ? Number(((price / original_price) - 1).toFixed(2)) : '',
              price_per_sm_with_vat: price_per_sm ? round(price_per_sm, 2) : '',
              parking_indoor_price_with_vat: project_data?.parking_indoor_price ? round(project_data?.parking_indoor_price, 2) : '',
              parking_outdoor_price_with_vat: project_data?.parking_outdoor_price ? round(project_data?.parking_outdoor_price, 2) : '',
            } : {
              price_without_vat: price ? round(price, 2) : '',
              original_price_without_vat: original_price ? round(original_price, 2) : '',
              original_price_per_sm_without_vat: original_price_per_sm ? round(original_price_per_sm, 2) : '',
              price_change: price && original_price && price / original_price !== 1 ? Number(((price / original_price) - 1).toFixed(2)) : '',
              price_per_sm_without_vat: price_per_sm ? round(price_per_sm, 2) : '',
              parking_indoor_price_without_vat: project_data?.parking_indoor_price ? round(project_data?.parking_indoor_price, 2) : '',
              parking_outdoor_price_without_vat: project_data?.parking_outdoor_price ? round(project_data?.parking_outdoor_price, 2) : '',
            },
          },
          total_area: total_area ?? '',
          orientation: orientation ?? '',
          first_seen,
          days_on_market: DOM,
          currency: getCurrency(),
          url: url ?? '',
        }),
      );

      if (exportData.length === 0) {
        message.destroy();
        message.info(t('market.export.no_data'), 2);
        return;
      }

      if (exportFormat === 'CSV') {
        csvExporter.options.filename = `${options.filename}${getVATText()}`;
        csvExporter.generateCsv(exportData);
      } else {
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${options.filename}${getVATText()}.xlsx`);
      }
      message.destroy();
      message.success(t('market.export.data_prepared'), 2);
    } catch (error) {
      logError(error);
      message.error(t('error.generic'), 2);
    }
  };

  return (
    <Popover
      overlayClassName='report-actions'
      placement='bottom'
      content={
        <>
          <Button
            css={styles.download}
            icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
            onClick={() => handleButtonClick('CSV')}
            type='text'
          >
            {t('market.button.export_csv')}
          </Button>
          <Button
            css={styles.download}
            icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
            onClick={() => handleButtonClick('XLSX')}
            type='text'
          >
            {t('market.button.export_xlsx')}
          </Button>
        </>
      }
    >
      <div
        role='button'
        tabIndex={0}
        className='w-10 h-10'
      >
        <ArrowDownTrayIcon className='w-10 h-10' />
      </div>
    </Popover>
  );
};
