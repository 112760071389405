/** @jsxImportSource @emotion/react */
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Segmented } from 'antd';
import { useStoreState } from '../../../../hooks';
import { useFilter } from '../useFilter';
import { Availability } from '../../../../store/types';
import { getReportExportFileName } from '../../../../utils/utils';
import { CardPopover } from '../CardPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { fetchBlockFloorOverviewData } from '../../../../api';
import { PricePerSmCalcType } from '../../../TopBar/SettingsPopover';
import { FloorOverviewResponse } from '../../../../api/types';
import { Card } from '../../../Card';
import { styles } from './styles';
import { FloorOverviewTable } from './FloorOverviewTable';

export interface Data {
  category: string;
  state: Availability;
  value: number;
}

enum FilterEnum {
  AVAILABLE,
  SOLD,
}

export const FloorOverview: FC = () => {
  const { t } = useTranslation();
  const { paramsPM } = useStoreState((state) => state.filters);
  const [data, setData] = useState<FloorOverviewResponse>();
  const [loading, setLoading] = useState(true);
  const pngRef = useRef(null);
  const { filterValue, handleChange } = useFilter(FilterEnum.AVAILABLE);
  const pricePerSmCalculation = useStoreState(
    (state) =>
      state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const { profile } = useStoreState((state) => state.user);
  const isSold = useStoreState((state) => state.user.profile?.reserved_as_sold);

  useEffect(() => {
    if (paramsPM?.dashboardId && paramsPM?.date) {
      setLoading(true);
      fetchBlockFloorOverviewData(
        paramsPM.dashboardId,
        paramsPM.date,
        pricePerSmCalculation,
      )
        .then((response) => setData(response.data))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paramsPM, pricePerSmCalculation, profile]);

  const fileName = getReportExportFileName(
    paramsPM?.name || 'report',
    t('dashboard.sale_detail.dashboard.floor_overview.title'),
  );

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
      <Card
        css={styles.card}
        extra={
          !loading && (
            <CardPopover onDownloadPNG={() => downloadPNG(fileName, pngRef)} />
          )
        }
        title={t('dashboard.floor_overview.title', 'Overview')}
        subtitle={t('dashboard.floor_overview.subtitle')}
      >
        <div css={styles.segmentedContainer}>
          <Segmented
            value={filterValue}
            options={[
              {
                value: FilterEnum.AVAILABLE,
                label: t('enums.state.available', 'Available'),
              },
              {
                value: FilterEnum.SOLD,
                label: isSold
                  ? t(
                      'enums.state.sales_reservations',
                      'Sales and Reservations',
                    )
                  : t('enums.state.sales', 'sales'),
              },
            ]}
            onChange={handleChange}
          />
        </div>
        <div>
          <FloorOverviewTable
            available={
              isSold
                ? data?.reserved_sold.available
                : data?.reserved_available.available
            }
            sold={
              isSold ? data?.reserved_sold.sold : data?.reserved_available.sold
            }
            filterValue={filterValue}
          />
        </div>
      </Card>
    </div>
  );
};
