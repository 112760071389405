/* eslint-disable import/no-default-export */
import dayjs from 'dayjs';

import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(duration);
dayjs.extend(minMax);

export default dayjs;
