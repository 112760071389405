import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Pagination as AntdPagination, PaginationProps } from 'antd';

type Props = {
  prefixIcon?: React.ReactNode;
  title?: string;
} & PaginationProps;

export const Pagination: FC<Props> = ({ prefixIcon, title, ...segmentedProps }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState<number | null>(null);

  useEffect(() => {
    if (ref.current) {
      setContainerHeight(ref.current.getBoundingClientRect().height);
    }
  }, []);

  return (
    <div
      className='space-x-4 flex items-center justify-center rounded-[20px] border-b border-l border-t border-bmdarkgray text-bmblue pl-6 select-none w-auto z-50 bg-white'
      style={{ height: containerHeight || 'auto' }}
    >
      {(prefixIcon || title) && (
        <div className='flex space-x-2 items-center justify-center z-20'>
          {prefixIcon && prefixIcon}
          {title && <span>{title}</span>}
        </div>
      )}
      <div ref={ref}>
        <AntdPagination
          className='pagination-segmented rounded-full border border-bmdarkgray p-1 cursor-pointer'
          {...segmentedProps}
        />
      </div>
    </div>
  );
};
