import { Cog8ToothIcon, TrashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReportActions } from '../hooks/useReportActions';
import { MergedReport } from './market/reports';

export const ReportItemActions = React.memo(
  ({
    onEdit,
    onRemove,
    itemRef,
    report,
  }: {
    onEdit: (event: MouseEvent) => void;
    onRemove: (event: MouseEvent) => void;
    itemRef: React.RefObject<HTMLDivElement>;
    report: MergedReport;
  }) => {
    const { t } = useTranslation();
    const { isBottom } = useReportActions(itemRef);
    const disableEdit =
      report.primary &&
      (!report.primary.dates || report.primary.dates.length === 0);

    return (
      <div
        className={`bg-white rounded-3xl text-gray-500 drop-shadow-lg py-4 px-2 font-medium absolute ${
          isBottom ? 'bottom-[3rem]' : 'top-0'
        } right-[-3rem]`}
      >
        <button
          type='button'
          data-cy='report-item-edit'
          onClick={onEdit}
          disabled={disableEdit ?? false}
          className={clsx(
            'flex w-full py-1 px-2 rounded-xl mb-2',
            disableEdit
              ? 'cursor-not-allowed bg-gray-100 text-gray-400'
              : 'hover:bg-gray-200',
          )}
        >
          <Cog8ToothIcon className='w-8 mr-2 stroke-2' /> {t('common.edit')}
        </button>
        <button
          type='button'
          className='flex w-full py-1 px-2 rounded-xl hover:bg-gray-200'
          onClick={onRemove}
        >
          <TrashIcon className='w-8 mr-2 stroke-2' /> {t('common.delete')}
        </button>
      </div>
    );
  },
);
