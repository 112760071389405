import { FC, useEffect, useState } from 'react';
import { Button, Table, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import {
  useStoreActions,
  useStoreState,
  useRegionFormatting,
  useFlatLayout,
  useBreakpoints,
} from '../../hooks';
import {
  getUniqueValues,
  roundUpdate,
  sortByString,
  transformPricingDataForTable,
} from '../../utils/utils';
import { getColumns } from './columnConfig';
import { PriceListTableData } from '../../api/mockData/priceListData';
import { ColumnFiltersList } from '../ColumnFiltersList/ColumnFiltersList';
import { onSaveColumnFilters } from '../ColumnFiltersList/columnFilters';
import { Availability, ProjectType } from '../../store/types';
import { formatDifference } from '../../utils';
import { InfoPopover } from '../InfoPopover';

type Props = {
  isCompare?: boolean;
  selectCompare?: (id: number, action: 'add' | 'remove') => void;
  selectedUnits?: number[];
};

export const PriceListTable: FC<Props> = ({
  isCompare,
  selectCompare,
  selectedUnits,
}) => {
  const storedIndexes = localStorage.getItem('priceListDataIndexes');
  const storedFilters = localStorage.getItem('priceListFilters');
  const smallDeviceFilters = [
    'floor',
    'exterior_area',
    'price_difference',
    'current_price',
  ];
  const mediumDeviceFilters = ['floor', 'exterior_area'];
  const { t } = useTranslation();
  const [isColumnFilterVisible, setIsColumnFilterVisible] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth] = useState(window.innerWidth);
  const [columnDataIndexes, setColumnDataIndexes] = useState<string[]>([]);
  // eslint-disable-next-line no-nested-ternary
  const getInitialFilterValues = (): string[] => {
    if (windowWidth < 1024) {
      return smallDeviceFilters;
    }
    if (windowWidth < 1600) {
      return mediumDeviceFilters;
    }
    return [];
  };
  const [columnsFilterValues, setColumnFilterValues] = useState<string[]>(
    getInitialFilterValues(),
  );
  const [isMouseOver, setIsMouseOver] = useState(false);
  const { breakpoints } = useBreakpoints();
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  const { formatCurrency, formatAreaUnits, applyRounding, sortLayouts } =
    useRegionFormatting();
  const { getLayout, getLayoutAbbr } = useFlatLayout();
  const pricingDataUpdated = useStoreState(
    (state) => state.priceList.pricingData,
  );

  const data = transformPricingDataForTable(pricingDataUpdated);

  const isHouse = !!data && !!data[0]?.floor_count;

  const { showPricePerSM, pageSize, selectedPricingId } = useStoreState(
    (state) => state.priceList,
  );
  const setSelectedPricingId = useStoreActions(
    (actions) => actions.priceList.setSelectedPricingId,
  );
  const { setPageSize } = useStoreActions((actions) => actions.priceList);
  const columns = getColumns(
    t,
    formatCurrency,
    formatAreaUnits,
    getUniqueValues((data ?? []).map((v) => v.layout)).sort(sortLayouts),
    getUniqueValues((data ?? []).map((v) => v.availability)).sort(sortByString),
    getLayout,
    getLayoutAbbr,
    showPricePerSM,
    sortLayouts,
    isRent,
    isHouse,
    isCompare,
    selectedUnits,
  );
  const rowClassName: TableProps<PriceListTableData>['rowClassName'] = (
    record,
  ) => (record.id === selectedPricingId ? 'selected-row' : '');

  const onRow: TableProps<PriceListTableData>['onRow'] = ({ id }) => ({
    onClick: () => {
      if (isCompare && selectCompare) {
        selectCompare(id, selectedUnits?.includes(id) ? 'remove' : 'add');
      } else {
        setSelectedPricingId(id);
      }
    },
  });

  const onChangeFilter = (value: string): void => {
    if (columnsFilterValues.includes(value)) {
      setColumnFilterValues(columnsFilterValues.filter((val) => val !== value));
    } else {
      if (columnDataIndexes.length - columnsFilterValues.length === 2) return;
      setColumnFilterValues((prev) => [...prev, value]);
    }
  };

  const heightPageSize = Math.floor((windowHeight - 200) / 60);
  const slicedColumns = columns.slice(1); // This removes the first and last item

  useEffect(() => {
    if (!columnDataIndexes.length) {
      setColumnDataIndexes(
        storedIndexes
          ? JSON.parse(storedIndexes)
          : slicedColumns.map((col) => col.dataIndex as string),
      );
    }
  }, [columnDataIndexes.length, slicedColumns, storedIndexes]);

  useEffect(() => {
    if (!columnsFilterValues.length && storedFilters) {
      setColumnFilterValues(JSON.parse(storedFilters));
    }
  }, [columnsFilterValues.length, storedFilters]);

  useEffect(() => {
    const handleResize = (): void => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const onHeaderRow: TableProps<PriceListTableData>['onHeaderRow'] = () => ({
    onMouseEnter: () => setIsMouseOver(true),
    onMouseLeave: () => setIsMouseOver(false),
  });

  const onSaveFilters = (): void => {
    onSaveColumnFilters(
      'priceListDataIndexes',
      columnDataIndexes,
      'priceListFilters',
      columnsFilterValues,
    );
    setIsColumnFilterVisible(false);
  };

  const onResetFilters = (): void => {
    localStorage.removeItem('priceListDataIndexes');
    localStorage.removeItem('priceListFilters');
    setColumnFilterValues([]);
  };

  const filteredColumns = columns
    .map((col) => {
      if (col.dataIndex === 'new_price') {
        return {
          ...col,
          render: (value: any, record: any) =>
            record.availability === Availability.AVAILABLE
              ? formatCurrency(
                  showPricePerSM
                    ? record.new_price_per_sm
                    : applyRounding(value),
                )
              : null,
        };
      }
      if (col.dataIndex === 'price_difference') {
        return {
          ...col,
          render: (value: any, record: any) => {
            const update = roundUpdate(
              record.recommended_price ?? 0,
              value ?? 0 * 100,
              record.current_price,
              applyRounding,
            );
            return record.availability === Availability.AVAILABLE ? (
              <div className='flex gap-2 items-center'>
                {update !== 0 && <span>{formatDifference(update, true)}</span>}
                <span>
                  {record.is_update_frozen && (
                    <InfoPopover
                      popoverProps={{
                        content: t(
                          'pricing.tooltip.frozen',
                          'The price of this unit was changed in the past month and we don’t recommend further changes.',
                        ),
                      }}
                    />
                  )}
                </span>
              </div>
            ) : null;
          },
        };
      }
      return col;
    })
    .filter(
      (col) =>
        col.dataIndex &&
        !columnsFilterValues.includes(col.dataIndex.toString()),
    )
    .sort(
      (a, b) =>
        columnDataIndexes.indexOf(a.dataIndex as string) -
        columnDataIndexes.indexOf(b.dataIndex as string),
    );

  return (
    <div className='relative'>
      <div
        className='absolute items-center flex cursor-pointer z-10 right-5 top-4'
        onClick={() => setIsColumnFilterVisible(!isColumnFilterVisible)}
      >
        <EllipsisHorizontalIcon className='w-8 storke-2 text-bmgray' />
      </div>
      <Table<PriceListTableData>
        onRow={onRow}
        rowKey='id'
        rowClassName={rowClassName}
        dataSource={data}
        columns={filteredColumns}
        className={`price-list-table ${!isMouseOver && 'hide-filters'}`}
        onHeaderRow={onHeaderRow}
        showSorterTooltip={false}
        pagination={{
          pageSize: pageSize || heightPageSize,
          onShowSizeChange: (_, size) => setPageSize(size),
        }}
        scroll={{ x: breakpoints.xl ? undefined : true }}
      />
      {isColumnFilterVisible && (
        <div className='absolute -right-14 top-14 flex flex-col bg-white rounded-2xl shadow-xl p-4 min-w-[300px] z-20 space-y-6 text-bmblue select-none'>
          <span className='font-bold p-4 flex items-center'>
            Selected Columns
          </span>
          <ColumnFiltersList
            columnDataIndexes={columnDataIndexes}
            setColumnDataIndexes={setColumnDataIndexes}
            columnsFilterValues={columnsFilterValues}
            onChangeFilter={onChangeFilter}
          />
          <div className='flex justify-evenly items-center space-x-4 p-4'>
            <Button
              className='bm-default-btn'
              onClick={() => setIsColumnFilterVisible(false)}
            >
              Cancel
            </Button>
            <Button className='bm-default-btn' onClick={() => onResetFilters()}>
              Reset
            </Button>
            <Button className='bm-submit-btn' onClick={() => onSaveFilters()}>
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
