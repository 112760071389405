import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as ToggleGroup from '@radix-ui/react-toggle-group';

type LayoutToggleProps = {
  layouts: string[];
  value: string;
  setValue: (value: string) => void;
};

export const LayoutToggle: FC<LayoutToggleProps> = ({
  layouts,
  value,
  setValue,
}) => {
  const { t } = useTranslation();

  return (
    <ToggleGroup.Root
      type='single'
      value={value}
      onValueChange={(layout): void => {
        if (layout) setValue(layout);
      }}
      className='border-[1px] border-[#BDBDBD] rounded-[25px] p-[2px] flex bg-[#F1F1F1] h-fit overflow-hidden'
    >
      {layouts.map((layout) => (
        <ToggleGroup.Item
          className='h-fit whitespace-nowrap data-[state=on]:bg-[#FFFFFF] data-[state=off]:hover:bg-[#D9D9D9] data-[state=on]:shadow-[4px_0px_25px_3px_rgba(0,0,0,0.15)] data-[state=on]:font-bold data-[state=on]:text-bmblue text-[#828282] text-[12px] px-4 py-[4px] rounded-[27px]'
          value={layout}
        >
          {t(`enums.eu_layouts_abbreviation.${layout}`)}
        </ToggleGroup.Item>
      ))}
    </ToggleGroup.Root>
  );
};
