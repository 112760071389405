import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SegmentedValue } from 'antd/es/segmented';
import Segmented from 'antd/lib/segmented';
import { useParams } from 'react-router-dom';
import { useStoreActions, useStoreState } from '../../../hooks';
import { FloorOverviewTable } from '../../market/blocks/FloorOverview/FloorOverviewTable';
import { FloorOverviewProjectData } from '../../../api/mockData/projectData';
import { ChartCard } from '../../ChartCard';

enum FilterEnum {
  AVAILABLE,
  SOLD,
}

export const FloorOverview: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const profile = useStoreState((state) => state.user.profile);
  const reserved_as_sold = useStoreState((state) => state.user.profile?.reserved_as_sold);
  const { fetchProjectFloorOverview } = useStoreActions((action) => action.project);
  const [filterValue, setFilterValue] = useState<SegmentedValue>(FilterEnum.AVAILABLE);
  const [data, setData] = useState<FloorOverviewProjectData | null>(null);

  useEffect(() => {
    if (profile && projectId) {
      const { VAT_included, price_per_sm_calculation } = profile;
      fetchProjectFloorOverview({
        project_id: Number(projectId),
        reserved_as_sold: !!reserved_as_sold,
        with_vat: VAT_included,
        price_per_sm_calculation,
      }).then((res: FloorOverviewProjectData) => {
        setData(res);
      });
    }
  }, [fetchProjectFloorOverview, projectId, reserved_as_sold]);

  const handleChange = (value: SegmentedValue): void => {
    setFilterValue(value);
  };

  const transformedData =
    {
      available: data?.available?.map((item) => ({
        ...item,
        average_floor_area: item.average_total_area || item.average_floor_area,
      })) || [],
      sold: data?.sold?.map((item) => ({
        ...item,
        average_floor_area: item.average_total_area || item.average_floor_area,
      })) || [],
    };

  return (
    <div style={{ height: '100%' }}>
      <ChartCard
        className='flex flex-col h-full'
        title={t('dashboard.floor_overview.title', 'Overview')}
        zoomInHeader
        subtitle={t('dashboard.floor_overview.subtitle')}
        controls={
          <div className='segmented-controls-wrapper mb-2'>
          <Segmented
            value={filterValue}
            options={[
              {
                value: FilterEnum.AVAILABLE,
                label: t('enums.state.available', 'Available'),
              },
              {
                value: FilterEnum.SOLD,
                label: t('enums.state.sold', 'Sold'),
              },
            ]}
            onChange={handleChange}
          />
          </div>
        }
        chart={
          <FloorOverviewTable
            available={transformedData.available}
            sold={transformedData.sold}
            filterValue={filterValue}
          />
        }
      />
    </div>
  );
};
